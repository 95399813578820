const URL = "https://www.myciright.com/Ciright";

const AppConfig = {
  API: {
    ENDPOINT: URL + "/api",
    CA_ENDPOINT: "https://ca-server.ciright.com/api",
    USER_PROFILE:
      URL +
      "/ajaxCall-photo.htm?flag=employeePhoto&compress=0&colorCode=veuit&id=",
    MANU_IMAGE:
      URL + "/ajaxCall-photo.htm?flag=veuitNetworkManufactLogo&compress=0&id=",
    PRODUCT_IMAGE:
      URL + "/ajaxCall-photo.htm?flag=veuitNetworkProductImage&compress=0&id=",
    EPISODE_IMAGE:
      URL + "/ajaxCall-photo.htm?flag=veuitNetworkProductImage&compress=0&id=",

    SUBSCRIPTION_ID: 9329,
    VERTICAL_ID: 2819,
    APP_ID: 2821,
    SPHERE_TYPE_URL: "ciright-studios.htm",
    SPHERE_TYPE_ID: 2,
    CA_APP_ID: 2459,
    ABOUT_DECK_URL: "veuit-deck",
    CIRIGHT_WORLD_VERTICAL_ID: 18,
    CIRIGHT_WORLD_SUBSCRIPTION_ID: 9329,
  },
};

export default AppConfig;
