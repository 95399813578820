import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Modal } from "react-bootstrap";
import { gsap } from "gsap";
import FsLightbox from "fslightbox-react";
import Banner from "../../components/banner/banner.component";
import SeriesList from "../../components/series-list/series-list.component";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import ReactJWPlayer from "react-jw-player";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";

import {
  selectHomeDataStatus,
  selectTabList,
  selectOriginalSeries,
  selectBrands,
  selectMoviesCategory,
  selectEmployeeData,
} from "../../store/home/home.selectors";
import {
  homeSyncStart,
  tabListSyncStart,
  originalSeriesSyncStart,
  brandsSyncStart,
  moviesCategorySyncStart,
  setSigninRedirectPath,
} from "../../store/home/home.actions";
import MovieList from "../../components/movie-list/movie-list.component";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const mapStateToProps = (state) => {
  return {
    selectHomeDataStatus: selectHomeDataStatus(state),
    selectTabList: selectTabList(state),
    selectOriginalSeries: selectOriginalSeries(state),
    selectBrands: selectBrands(state),
    selectMoviesCategory: selectMoviesCategory(state),
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      homeSyncStart,
      tabListSyncStart,
      originalSeriesSyncStart,
      brandsSyncStart,
      moviesCategorySyncStart,
      setSigninRedirectPath,
    },
    dispatch
  ),
});

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);

      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);

      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);

      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);

      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);

      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);

      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);

      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);

    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;

    setEndOption.x = 0;

    setEndOption.y = 0;

    setEndOption.rotate = 0;

    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trailerPlay: false,
      trailerUrl: "",
      isLoading: 1,
      checkingUpdate: false,
      bannerMap: [],
      newReleaseMap: [],
      originalSeriesMap: [],
      brandsMap: [],
      moviesCategoryMap: [],
      classicMap: [],
      independentMap: [],
      securityTabListMap: [],
      isLogin: false,
      isShowModel: false,
      modelUrl: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      homeSyncStart,
      selectHomeDataStatus,
      tabListSyncStart,
      originalSeriesSyncStart,
      brandsSyncStart,
      selectOriginalSeries,
      selectBrands,
      moviesCategorySyncStart,
    } = this.props;

    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      employeeId: this.props.employeeData?.employeeId,
      loginEmployeeId: this.props.employeeData?.employeeId,
      appId: AppConfig.API.APP_ID,
      timeZone: null,
    };

    this.props.setSigninRedirectPath({ signinRefirectPath: null });
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      payload.timeZone = null;
      this.setState({ isLogin: true });
    } else {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      payload.timeZone = timeZone;
      this.setState({ isLogin: false });
    }

    if (
      selectHomeDataStatus.initialSynced &&
      selectHomeDataStatus.syncinghome &&
      selectHomeDataStatus.bannerList !== null
    ) {
      this.setState({ bannerMap: selectHomeDataStatus.bannerList });
    } else {
      homeSyncStart(payload);
    }

    if (
      selectMoviesCategory.initialSynced &&
      selectMoviesCategory.moviesCategoryList !== null
    ) {
      this.setState({
        moviesCategoryMap: selectMoviesCategory.moviesCategoryList,
      });
    } else {
      moviesCategorySyncStart(payload);
    }

    moviesCategorySyncStart(payload);

    if (
      selectOriginalSeries.initialSynced &&
      selectOriginalSeries.originalSeriesList !== null
    ) {
      this.setState({
        originalSeriesMap: selectOriginalSeries.originalSeriesList,
      });
    } else {
      originalSeriesSyncStart(payload);
    }

    if (selectBrands.initialSynced && selectBrands.brandsList !== null) {
      this.setState({ brandsMap: selectBrands.brandsList });
    } else {
      brandsSyncStart(payload);
    }

    this.interval = setInterval(() => this.setState({ isLoading: 0 }), 5000);

    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      if (selectTabList.initialSynced && selectTabList.tabList !== null) {
        this.setState({ securityTabListMap: selectTabList.tabList });
      } else {
        tabListSyncStart(payload);
      }
    } else {
      this.props.history.push("/");
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      selectHomeDataStatus,
      selectOriginalSeries,
      selectBrands,
      selectMoviesCategory,
      selectTabList,
    } = this.props;
    if (selectHomeDataStatus.initialSynced) {
      if (
        prevProps.selectHomeDataStatus.bannerList !==
        selectHomeDataStatus.bannerList
      ) {
        if (selectHomeDataStatus.syncinghome) {
          this.setState({ bannerMap: selectHomeDataStatus.bannerList });
        } else {
          if (selectHomeDataStatus.message != null) {
            this.setState({ bannerMap: [] });
            NotificationManager.error(selectHomeDataStatus.message, "", 2000);
          }
        }
      }
    }

    if (selectMoviesCategory.initialSynced) {
      if (
        prevProps.selectMoviesCategory.moviesCategoryList !==
        selectMoviesCategory.moviesCategoryList
      ) {
        this.setState({
          moviesCategoryMap: selectMoviesCategory.moviesCategoryList,
          checkingUpdate: !this.state.checkingUpdate,
        });
      }
    }

    if (selectOriginalSeries.initialSynced) {
      if (
        prevProps.selectOriginalSeries.originalSeriesList !==
        selectOriginalSeries.originalSeriesList
      ) {
        this.setState({
          originalSeriesMap: selectOriginalSeries.originalSeriesList,
        });
      }
    }

    if (selectBrands.initialSynced) {
      if (prevProps.selectBrands.brandsList !== selectBrands.brandsList) {
        this.setState({ brandsMap: selectBrands.brandsList });
      }
    }

    if (selectTabList.initialSynced) {
      if (prevProps.selectTabList.tabList !== selectTabList.tabList) {
        this.setState({
          securityTabListMap: selectTabList.tabList,
          checkingUpdate: !this.state.checkingUpdate,
        });
      }
    }
  }

  playTrailer = (trailerUrl) => {
    this.setState({
      trailerUrl: trailerUrl,
      trailerPlay: !this.state.trailerPlay,
    });
  };

  addToWatchList = (movieId, movieWatchListId, tagName) => {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      const payload = {
        movieId: movieId,
        employeeId: this.props.employeeData?.employeeId,
        movieWatchListId: movieWatchListId,
      };

      UserService.addToWatchList(payload)
        .then((res) => {
          if (res.error != null) {
            NotificationManager.warning(
              res.error.response.data.message,
              "",
              2000
            );
          } else {
            if (res["status"]) {
              const payloadData = {
                subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
                verticalId: AppConfig.API.VERTICAL_ID,
                employeeId: this.props.employeeData?.employeeId,
                loginEmployeeId: this.props.employeeData?.employeeId,
              };

              this.props.moviesCategorySyncStart(payloadData);
              if (movieWatchListId !== -1) {
                NotificationManager.success(
                  "Movie Removed from My Watchlist.",
                  "",
                  2000
                );
              } else {
                NotificationManager.success(
                  "Movie Added to My Watchlist.",
                  "",
                  2000
                );
              }
            } else {
              NotificationManager.error(res["message"], "", 2000);
            }
          }
        })
        .catch((err) => {
          NotificationManager.error(err, "", 2000);
        });
    } else {
      this.props.setSigninRedirectPath({ signinRefirectPath: "/" });
      this.props.history.push("/authentication/sign-in");
    }
  };

  animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };

  handleClose = () => {
    this.setState({ isShowModel: false, modelUrl: "" });
  };

  render() {
    return (
      <div>
        {this.state.isLoading === 1 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: window.innerHeight,
                width: "100%",
              }}
            >
              <Loader />
            </div>
          </>
        ) : (
          <>
            <Modal
              show={this.state.isShowModel}
              centered
              dialogClassName="modal-fullscreen"
              id="app-modal"
            >
              <div id="modal-content-wrapper">
                <Modal.Header
                  closeButton
                  onHide={this.handleClose}
                  backdrop="static"
                ></Modal.Header>
                <Modal.Body id="main-screen-modal">
                  <ReactJWPlayer
                    playerId="movie"
                    playerScript="https://cdn.jwplayer.com/libraries/zFFedrqG.js"
                    file={this.state.modelUrl}
                    isAutoPlay={true}
                    // aspectRatio="20:9"
                    customProps={{
                      playbackRateControls: [1, 1.25, 1.5],
                      cast: {},
                    }}
                  />
                </Modal.Body>
              </div>
            </Modal>
            {this.state.bannerMap != null && this.state.bannerMap.length > 0 ? (
              <section
                id="home"
                className="iq-main-slider p-0 iq-rtl-direction"
              >
                <hr className="set-gradient-border1"></hr>
                <FsLightbox
                  toggler={this.state.trailerPlay}
                  sources={[
                    <iframe
                      src={this.state.trailerUrl}
                      title=" "
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                      style={{ width: "700px", height: "650px" }}
                    />,
                  ]}
                />

                <div id="prevHome" className="swiper-button swiper-button-prev">
                  <i className="fa fa-chevron-left"></i>
                </div>
                <div id="nextHome" className="swiper-button swiper-button-next">
                  <i className="fa fa-chevron-right"></i>
                </div>

                <Swiper
                  navigation={{
                    prevEl: "#prevHome",
                    nextEl: "#nextHome",
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  onInit={() => {
                    this.animationInit();
                  }}
                  onSlideChangeTransitionStart={() => this.animationInit()}
                  loop={this.state.bannerMap.length > 1 ? true : false}
                  id="home-slider"
                  className="slider m-0 p-0"
                >
                  {this.state.bannerMap.map((item, key) => {
                    return (
                      <SwiperSlide
                        className="slide slick-bg s-bg-1"
                        as="li"
                        key={key}
                      >
                        <Banner
                          key={key}
                          originalSeriesMap={item}
                          playTrailer={this.playTrailer}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <hr className="set-gradient-border"></hr>
              </section>
            ) : (
              ""
            )}

            {/* {this.state.newReleaseMap != null &&
            this.state.newReleaseMap.length > 0
              ? this.state.securityTabListMap
                  .filter(
                    (item) =>
                      item.tabTypeUrl ===
                      "veuit-network-home-movie-new-releases.htm"
                  )
                  .map((item, key) => (
                    <MovieList
                      key={key}
                      moviesMap={this.state.newReleaseMap}
                      checkingUpdate={this.state.checkingUpdate}
                      id={17}
                      tagName={item.tabName}
                      playNowUrl="/movie-details"
                      viewAllUrl="/view-all/new-releases"
                      addToWatchList={this.addToWatchList}
                    />
                  ))
              : ""} */}

            {this.state.moviesCategoryMap != null &&
            this.state.moviesCategoryMap.length > 0 ? (
              <>
                {this.state.moviesCategoryMap?.map((item, key) => {
                  let number = key + 1;
                  return (
                    <MovieList
                      key={key}
                      moviesMap={item.moviesCategoryMap}
                      checkingUpdate={this.state.checkingUpdate}
                      id={key + 1}
                      tagName={item.moviesCategoryName}
                      playNowUrl="/movie-details"
                      viewAllUrl={"/view-all/" + item.moviesCategoryId}
                      addToWatchList={this.addToWatchList}
                    />
                  );
                })}
              </>
            ) : (
              ""
            )}

            {/* {this.state.brandsMap?.length > 0 ? (
              <BrandList
                resultMap={this.state.brandsMap}
                id={9}
                tagName="Brands"
                viewAllUrl="/view-all/brands"
              />
            ) : (
              ""
            )} */}

            {this.state.originalSeriesMap?.length > 0 ? (
              <SeriesList
                seriesMap={this.state.originalSeriesMap}
                id={2}
                tagName="Original Series"
                playNowUrl="/series-details"
                viewAllUrl=""
              />
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
