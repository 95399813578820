import React from "react"
import { Form, Modal } from "react-bootstrap"
import { NotificationManager } from "react-notifications"
import "react-notifications/lib/notifications.css"
import { ReactComponent as Add } from "../../assets/images/plus-add.svg"
import PaymentAddAddressModal from "./payment-add-address.component"
import Backdrop from "@material-ui/core/Backdrop"
import UserService from "../../services/user/user.service"
import AppConfig from "../../config/app.config"
import { Drawer } from "@material-ui/core"
import { ReactComponent as Footericon } from "../../assets/images/bottom-line.svg"
import Loader from "../Loader"

class PaymentAddressModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: 0,
      isAddAddressModalVisible: false,
      isEditAddressModalVisible: false,
      billingAddressmap: this.props.billingAddressmap,
      addressObject: null,
      activeAddress:
        this.props.defaultBillingAddress != null
          ? this.props.defaultBillingAddress
          : null,
      employeeId: this.props.employeeId,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    if (this.props.employeeId != null) {
      this.setState({
        employeeId: this.props.employeeId,
        activeAddress: this.props.defaultBillingAddress,
      })
    } else {
      this.props.history.goBack()
    }
  }

  hideModal = () => {
    if (this.state.isAddAddressModalVisible) {
      this.setState({
        isAddAddressModalVisible: false,
        isEditAddressModalVisible: false,
        addressObject: null,
        activeAddress: null,
      })
    } else {
      this.setState({
        isAddAddressModalVisible: false,
        isEditAddressModalVisible: false,
        addressObject: null,
      })
    }
    this.props.updateBillingAddress()
  }

  componentDidUpdate(prevProps, prevState) {
    const billingAddressmap = this.props.billingAddressmap
    if (billingAddressmap !== prevProps.billingAddressmap) {
      this.setState({ activeAddress: this.props.defaultBillingAddress })
    }
  }

  setDefaultAddress = () => {
    this.setState({
      isLoading: 1,
    })
    const address = this.state.activeAddress
    const payload = {
      employeeId: this.state.employeeId,
      appId: AppConfig.API.APP_ID,
      addressId: address.addressId,
      name: address.name,
      countryId: address.countryId,
      stateId: address.stateId,
      phone: address.phone,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      zipCode: address.zipCode,
      isDefault: 1,
    }

    UserService.addBillingAddress(payload)
      .then((res) => {
        if (res.error != null) {
          if (res.error?.response?.data?.message != null) {
            NotificationManager.error(res.error.response.data.message, "", 3000)
            this.setState({
              isLoading: 0,
            })
          } else {
            NotificationManager.error(
              "Could not set address is default.",
              "",
              3000
            )
            this.setState({
              isLoading: 0,
            })
          }
        } else {
          if (res["status"]) {
            this.setState({
              isLoading: 0,
            })
            this.props.updateBillingAddress()
            this.props.hideModal()
          } else {
            NotificationManager.error(
              "Could not set address is default.",
              "",
              3000
            )
            this.setState({
              isLoading: 0,
            })
          }
        }
      })
      .catch((err) => {
        NotificationManager.error(err, "", 3000)
        this.setState({
          isLoading: 0,
        })
      })
  }

  render() {
    return (
      <div>
        <Modal
          style={{ minHeight: "100vh" }}
          show={this.props.show}
          onHide={this.props.hideModal}
          backdrop="static"
          keyboard={false}
          centered
          id="billing-address-modal hide-mobile-modal"
        >
          {/* hide-mobile-modal */}
          {this.state.isLoading === 1 ? <Loader /> : ""}
          <div id="address-modal">
            <div className="customer-billing">
              <Modal.Header closeButton>
                <div>
                  <h4>Choose a billing address</h4>
                </div>
              </Modal.Header>
              <p>
                Please choose a billing address from your address book below, or{" "}
                <span>enter new billing address.</span>
              </p>
            </div>
            {this.props.billingAddressmap != null &&
              this.props.billingAddressmap.map((item, key) => {
                return (
                  <div
                    className="new-billing-add"
                    id="new-billing-add"
                    key={key}
                  >
                    <div className="newbilling">
                      <Form.Check
                        type={"radio"}
                        id={`check-api-${"radio"}`}
                      >
                        {this.state.activeAddress != null &&
                        item.addressId ===
                          this.state.activeAddress.addressId ? (
                          <Form.Check.Input
                            type={"radio"}
                            id="address-1"
                            name="address-1"
                            value={item.addressId}
                            className="new-billing-radio"
                            checked
                            onChange={(e) => {
                              this.setState({ activeAddress: item })
                            }}
                          />
                        ) : (
                          <Form.Check.Input
                            type={"radio"}
                            id="address-1"
                            name="address-1"
                            className="new-billing-radio"
                            value={item.addressId}
                            onChange={(e) => {
                              this.setState({ activeAddress: item })
                            }}
                          />
                        )}
                      </Form.Check>
                      <div className="new-billing-add-info ">
                        <h4>{item.name}</h4>
                        <p>
                          {item.address1}, {item.city}, {item.state}
                        </p>
                        <p>
                          {item.city}, {item.zipCode}
                        </p>
                        <p>{item.country}</p>
                        <p>Phone number: {item.phone}</p>
                      </div>
                    </div>
                    <div className="editaddress">
                      {this.state.activeAddress != null &&
                      item.addressId === this.state.activeAddress.addressId ? (
                        <p
                          onClick={() =>
                            this.setState({
                              isEditAddressModalVisible: true,
                              addressObject: item,
                            })
                          }
                        >
                          Edit address
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )
              })}

            <div
              className="add-new-address"
              onClick={() => this.setState({ isAddAddressModalVisible: true })}
            >
              <Add />
              <p>Add a new address</p>
            </div>
            <div className="add-new-address-btn">
              <button
                className="address-btn"
                onClick={this.setDefaultAddress}
              >
                Use this address
              </button>
            </div>
          </div>
        </Modal>
        <Drawer
          id="header-drawer"
          className="bottomDrawer"
          anchor={"bottom"}
          open={this.props.show}
          onClose={this.props.hideModal}
          style={{
            display:
              this.state.isAddAddressModalVisible ||
              this.state.isEditAddressModalVisible
                ? "none"
                : "",
          }}
        >
          {this.state.isLoading === 1 ? <Loader /> : ""}
          <div
            id="address-modal"
            className="mob-add-model"
          >
            <div
              className="footer-icon"
              onClick={this.props.hideModal}
            >
              <Footericon />
            </div>
            <div className="customer-billing">
              <h4>Choose a billing address</h4>
              <p>
                Please choose a billing address from your address book below, or{" "}
                <span>enter new billing address.</span>
              </p>
            </div>
            {this.props.billingAddressmap != null &&
              this.props.billingAddressmap.map((item, key) => {
                return (
                  <div
                    className="new-billing-add"
                    id="new-billing-add"
                    key={key}
                  >
                    <div className="newbilling">
                      <Form.Check
                        type={"radio"}
                        id={`check-api-${"radio"}`}
                      >
                        {this.state.activeAddress != null &&
                        item.addressId ===
                          this.state.activeAddress.addressId ? (
                          <Form.Check.Input
                            type={"radio"}
                            id="address-2"
                            name="address-2"
                            value={item.addressId}
                            className="new-billing-radio"
                            checked
                            onChange={(e) => {
                              this.setState({ activeAddress: item })
                            }}
                          />
                        ) : (
                          <Form.Check.Input
                            type={"radio"}
                            id="address-2"
                            name="address-2"
                            className="new-billing-radio"
                            value={item.addressId}
                            onChange={(e) => {
                              this.setState({ activeAddress: item })
                            }}
                          />
                        )}
                      </Form.Check>
                      <div className="new-billing-add-info ">
                        <h4>{item.name}</h4>
                        <p>
                          {item.address1}, {item.city}, {item.state}
                        </p>
                        <p>
                          {item.city}, {item.zipCode}
                        </p>
                        <p>{item.country}</p>
                        <p>Phone number: {item.phone}</p>
                      </div>
                    </div>
                    <div className="editaddress">
                      {this.state.activeAddress != null &&
                      item.addressId === this.state.activeAddress.addressId ? (
                        <p
                          onClick={() =>
                            this.setState({
                              isEditAddressModalVisible: true,
                              addressObject: item,
                            })
                          }
                        >
                          Edit address
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )
              })}
            <div
              className="add-new-address"
              onClick={() => this.setState({ isAddAddressModalVisible: true })}
            >
              <Add />
              <p>Add a new address</p>
            </div>
            <div className="add-new-address-btn">
              <button
                className="address-btn"
                onClick={this.setDefaultAddress}
              >
                Use this address
              </button>
            </div>
          </div>
        </Drawer>

        {this.state.isAddAddressModalVisible ||
        this.state.isEditAddressModalVisible ? (
          <PaymentAddAddressModal
            show={
              this.state.isAddAddressModalVisible ||
              this.state.isEditAddressModalVisible
            }
            hideModal={this.hideModal}
            name={
              this.state.isAddAddressModalVisible
                ? "Add a new address"
                : "Edit your address"
            }
            isEdit={this.state.isAddAddressModalVisible ? 0 : 1}
            addressObject={this.state.addressObject}
            employeeId={this.props.employeeId}
          />
        ) : (
          ""
        )}
      </div>
    )
  }
}

export default PaymentAddressModal
