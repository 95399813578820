import { Switch, Route } from "react-router-dom";
import MainLayout from "../layouts/main-layout";
import AuthenticationLayout from "../layouts/authentication-layout";
import { setLocation, setEmployeeData } from "../store/home/home.actions";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import LogoutMessage from "../components/LogoutMessage/logout-message";
import Backdrop from "@material-ui/core/Backdrop";
import { selectEmployeeData } from "../store/home/home.selectors";
import Loader from "../components/Loader";

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setLocation,
      setEmployeeData,
    },
    dispatch
  ),
});

class LayoutsRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
      isLoading: 1,
      logoutMsg: 0,
      isAutoLogin: 0,
    };
    this.props.setLocation({ location: this.props.location });
    this.getInterval = null;
  }

  componentDidMount() {
    var employeeData = localStorage.getItem("employeeData");
    if (employeeData != null && employeeData !== "") {
      try {
        employeeData = JSON.parse(employeeData);
      } catch (err) {}
    }
    if (employeeData != null) {
      this.props.setEmployeeData(employeeData);
      this.setState({ isLoading: 0 });
    } else {
      this.setState({ isLoading: 0 });
    }
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.state.location !== this.props.location) {
      this.props.setLocation({ location: this.props.location });
      this.setState({ location: this.props.location });
    }
  }

  render() {
    return this.state.isLoading === 1 ? (
      <Loader />
    ) : (
      <>
        {this.state.logoutMsg === 1 ? <LogoutMessage /> : ""}
        <Switch location={this.props.location}>
          <Route path="/authentication" component={AuthenticationLayout} />
          <Route path="/" component={MainLayout} />
        </Switch>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LayoutsRoute));
