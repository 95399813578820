import React from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import UserService from "../../services/user/user.service"
import { NotificationManager } from "react-notifications"
import "react-notifications/lib/notifications.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { format } from "date-fns"
import AppConfig from "../../config/app.config"
import user from "../../assets/images/user/user-profile.png"
import Backdrop from "@material-ui/core/Backdrop"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import VlinkQrmodal from "../../components/modal/vlink-qrmodal"
import { selectEmployeeData } from "../../store/home/home.selectors"
import { setSigninRedirectPath } from "../../store/home/home.actions"
import { ReactComponent as Profilevecor } from "../../assets/images/profile-Vector.svg"
import { ReactComponent as Profilevecor3 } from "../../assets/images/profile-Vector3.svg"
import { withRouter } from "react-router-dom"
import Loader from "../../components/Loader"

let date = new Date()
date.setDate(date.getDate() - 1)

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setSigninRedirectPath }, dispatch),
})

// const [value, onChange] = useState(new Date());

export class UserProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: 1,
      employeeId: "",
      name: "",
      email: "",
      dob: "",
      phone: "",
      password: "",
      profileImage: "",
      file: "",
      isEditInfo: 0,
      isProfileEdit: 0,
      isDeletePhoto: 0,
      value: new Date(),
      firstName: "",
      lastName: "",
    }
    this.handleName = this.handleName.bind(this)
    this.handlePhone = this.handlePhone.bind(this)
    this.handleDOB = this.handleDOB.bind(this)
    this.handleFirstName = this.handleFirstName.bind(this)
    this.handleLastName = this.handleLastName.bind(this)

    this.apiEmployeeData = null
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      this.getProfileData(this.props.employeeData?.employeeId)
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      })

      this.props.history.push("/authentication/sign-in")
    }
  }

  handleName(event) {
    this.setState({ name: event.target.value })
  }

  removeExtraSpace = (s) => s.replace(/\s/g, "")

  handlePhone(event) {
    const result = /^[0-9\b]+$/
    if (event.target.value === "" || result.test(event.target.value)) {
      this.setState({ phone: this.removeExtraSpace(event.target.value) })
    }
  }

  handleDOB(date) {
    this.setState({ dob: date })
  }

  handleProfileImage(e) {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      this.setState({
        file: reader.result,
        profileImage: reader.result,
      })
    }
    reader.readAsDataURL(file)
  }

  handleFirstName(event) {
    this.setState({ firstName: this.removeExtraSpace(event.target.value) })
  }

  handleLastName(event) {
    this.setState({ lastName: this.removeExtraSpace(event.target.value) })
  }

  getProfileData = (employeeId) => {
    UserService.getUserProfile(employeeId)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0 })
          NotificationManager.warning("Somthing Went Wrong", "", 2000)
        } else {
          if (res["status"]) {
            this.apiEmployeeData = {
              firstName: res["data"].firstName,
              lastName: res["data"].name
                .slice(res["data"].firstName.length)
                .trim(),
              dob:
                res["data"].birthDate !== ""
                  ? Date.parse(res["data"].birthDate)
                  : "",
              phone: res["data"].phone,
              profileImage:
                AppConfig.API.USER_PROFILE +
                employeeId +
                "&random=" +
                Date.now(),
            }
            this.setState({
              isLoading: 0,
              employeeId: employeeId,
              name: res["data"].name,
              email: res["data"].email,
              dob:
                res["data"].birthDate !== ""
                  ? Date.parse(res["data"].birthDate)
                  : "",
              phone: res["data"].phone,
              profileImage:
                AppConfig.API.USER_PROFILE +
                employeeId +
                "&random=" +
                Date.now(),
              file:
                AppConfig.API.USER_PROFILE +
                employeeId +
                "&random=" +
                Date.now(),
              firstName: res["data"].firstName,
              lastName: res["data"].name
                .slice(res["data"].firstName.length)
                .trim(),
            })
          } else {
            this.setState({ isLoading: 0 })
            NotificationManager.error(res["message"], "", 2000)
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0 })
        NotificationManager.error("Somthing Went Wrong", "", 2000)
      })
  }

  editUserInfo = () => {
    this.setState({ isEditInfo: 1 })
    // this.nameInput.focus();
    this.cancelUserProfile()
  }

  cancelUserInfo = () => {
    this.setState({ isEditInfo: 0 })
    this.getProfileData(this.state.employeeId)
  }

  saveUserInfo = () => {
    const name = `${this.state.firstName} ${this.state.lastName}`
    var pattern = new RegExp(/^[0-9\b]+$/)
    const rule = new RegExp(/^[a-zA-Z ]{2,40}$/)
    if (name === "") {
      NotificationManager.warning("Please Enter Name.", "", 2000)
    } else if (!rule.test(name)) {
      NotificationManager.warning(
        "Name must contains at list two letter with only characters, not allow numaric value.",
        "",
        2000
      )
    } else if (this.state.phone === "") {
      NotificationManager.warning("Please Enter Phone.", "", 2000)
    } else if (!pattern.test(this.state.phone)) {
      NotificationManager.warning("Please Enter Valid Phone.", "", 2000)
    } else if (this.state.phone.length !== 10) {
      NotificationManager.warning("Please Enter Valid Phone.", "", 2000)
    } else {
      var userProfileImage = ""
      if (this.state.profileImage !== "") {
        const imageBase64 = this.state.profileImage.split(",")
        userProfileImage = imageBase64[1]
      }
      const payload = {
        phone: this.state.phone,
        name: `${this.state.firstName} ${this.state.lastName}`,
        birthDate:
          this.state.dob !== "" && this.state.dob !== null
            ? format(new Date(this.state.dob), "MM/dd/yyyy")
            : "",
        isDeletePhoto: this.state.isDeletePhoto,
      }
      if (
        this.state.profileImage !== "" &&
        this.apiEmployeeData.profileImage !== this.state.profileImage
      ) {
        payload.photo = userProfileImage
      }
      this.setState({ isLoading: 1 })
      UserService.updateUserProfile(payload, this.state.employeeId)
        .then((res) => {
          if (res.error != null) {
            this.setState({ isLoading: 0 })
            NotificationManager.error(res.error.response.data.message, "", 2000)
          } else {
            if (res["status"]) {
              localStorage.setItem("employeeName", payload.name)
              const empData =
                JSON.parse(window.localStorage.getItem("employeeData")) ??
                this.props.employeeData

              const newEmpData = {
                ...empData,
                name: payload.name,
                phone: payload.phone,
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
              }
              window.localStorage.setItem(
                "employeeData",
                JSON.stringify(newEmpData)
              )
              this.setState({ isEditInfo: 0, isLoading: 0 })
              NotificationManager.success(
                "Data Updated Successfully.",
                "",
                2000
              )
              window.location.reload()
            } else {
              this.setState({ isLoading: 0 })
              NotificationManager.error(res["message"], "", 2000)
            }
          }
        })
        .catch((err) => {
          this.setState({ isLoading: 0 })
          NotificationManager.error(err, "", 2000)
        })
    }
  }

  editUserProfile = () => {
    this.setState({ isProfileEdit: 1 })
    this.cancelUserInfo()
  }

  deleteProfile = () => {
    this.setState({ isDeletePhoto: 1, profileImage: "" })
  }

  cancelUserProfile = () => {
    this.setState({
      isDeletePhoto: 0,
      profileImage: this.state.file,
      isProfileEdit: 0,
    })
    this.getProfileData(this.state.employeeId)
  }

  saveUserProfile = () => {
    var userProfileImage = ""
    if (this.state.profileImage !== "") {
      const imageBase64 = this.state.profileImage.split(",")
      userProfileImage = imageBase64[1]
    }
    const payload = {
      phone: this.state.phone,
      name: `${this.state.firstName} ${this.state.lastName}`,
      firstName: this.state.firstName,
      birthDate:
        this.state.dob !== "" && this.state.dob !== null
          ? format(new Date(this.state.dob), "MM/dd/yyyy")
          : "",
      photo: userProfileImage,
      isDeletePhoto: this.state.isDeletePhoto,
    }
    this.setState({ isLoading: 1 })
    UserService.updateUserProfile(payload, this.state.employeeId)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0 })
          NotificationManager.error(res.error.response.data.message, "", 2000)
        } else {
          if (res["status"]) {
            localStorage.setItem("employeeName", payload.name)
            this.setState({ isProfileEdit: 0, isLoading: 0 })
            NotificationManager.success(
              "Profile Updated Successfully.",
              "",
              2000
            )
            window.location.reload()
          } else {
            this.setState({ isLoading: 0 })
            NotificationManager.error(res["message"], "", 2000)
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0 })
        NotificationManager.error(err, "", 2000)
      })
  }
  onChangeCalendar = (newdate) => {
    this.setState({ value: newdate })
  }

  isFieldsChanged = () => {
    return (
      this.apiEmployeeData?.firstName !== this.state.firstName ||
      this.apiEmployeeData?.lastName !== this.state.lastName ||
      this.apiEmployeeData?.dob !== this.state.dob ||
      this.apiEmployeeData?.phone !== this.state.phone ||
      this.apiEmployeeData?.profileImage !== this.state.profileImage
    )
  }

  render() {
    return (
      <section className="m-profile setting-wrapper">
        {this.state.isLoading === 1 ? <Loader /> : ""}
        {/* <Container>
          <h4 className=" mb-4 basic-information">Basic information</h4>
          <Row>
            <Col
              lg="4"
              className="mb-3"
            >
              <div className="sign-user_card text-center">
                <div className="upload_profile d-inline-block">
                  <img
                    src={
                      this.state.profileImage !== ""
                        ? this.state.profileImage
                        : user
                    }
                    className="profile-pic avatar-130 rounded-circle img-fluid"
                    alt="user"
                  />
                  {this.state.isProfileEdit === 1 ? (
                    <label className="p-image">
                      <input
                        accept="image/*"
                        type="file"
                        onChange={(e) => this.handleProfileImage(e)}
                        style={{ display: "none" }}
                      />
                      <i className="ri-pencil-line upload-button"></i>
                    </label>
                  ) : (
                    ""
                  )}

                  {this.state.isProfileEdit === 1 ? (
                    <label
                      className="d-image"
                      onClick={this.deleteProfile}
                    >
                      <i className="ri-delete-bin-line upload-button"></i>
                    </label>
                  ) : (
                    ""
                  )}
                </div>
                <h4 className="mb-3 mt-3">{this.state.name}</h4>

                {this.state.isProfileEdit === 0 ? (
                  <Link
                    to="#"
                    onClick={this.editUserProfile}
                    className="edit-save text-primary"
                  >
                    Edit
                  </Link>
                ) : (
                  <Link
                    to="#"
                    onClick={this.saveUserProfile}
                    className="edit-save text-primary"
                  >
                    Save
                  </Link>
                )}
                {this.state.isProfileEdit === 0 ? (
                  ""
                ) : (
                  <Link
                    to="#"
                    onClick={this.cancelUserProfile}
                    className="edit-cancel text-primary"
                  >
                    Cancel
                  </Link>
                )}
              </div>
            </Col>
            <Col lg="8">
              <div className="sign-user_card">
                <h5 className="mb-3 pb-3 a-border">Personal Details</h5>

                {this.state.isEditInfo === 0 ? (
                  <Link
                    to="#"
                    onClick={this.editUserInfo}
                    className="edit-icon text-primary"
                  >
                    Edit
                  </Link>
                ) : (
                  <Link
                    to="#"
                    onClick={this.saveUserInfo}
                    className="edit-icon text-primary"
                  >
                    Save
                  </Link>
                )}
                {this.state.isEditInfo === 0 ? (
                  ""
                ) : (
                  <Link
                    to="#"
                    onClick={this.cancelUserInfo}
                    className="edit-cancel-info text-primary"
                  >
                    Cancel
                  </Link>
                )}
                <Form
                  className="mt-4"
                  action="#"
                >
                  <Row className="row align-items-center justify-content-between mb-3">
                    <Col md="12">
                      <Form.Group className="form-group">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength="40"
                          className="form-control mb-0"
                          id="name"
                          ref={(input) => {
                            this.nameInput = input
                          }}
                          placeholder="Enter Your Name"
                          autoComplete="off"
                          value={this.state.name}
                          onChange={this.handleName}
                          readOnly={this.state.isEditInfo === 0 ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="row align-items-center justify-content-between mb-3">
                    <Col md="12">
                      <Form.Group className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control mb-0"
                          id="email"
                          placeholder="Enter Your Email"
                          autoComplete="off"
                          value={this.state.email}
                          readOnly={true}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-between mb-3">
                    <Col md="12">
                      <Form.Group className="form-group">
                        <Form.Label>Date of Birth</Form.Label>
                        <DatePicker
                          selected={this.state.dob}
                          dateFormat="MM/dd/yyyy"
                          maxDate={date}
                          onChange={this.handleDOB}
                          readOnly={this.state.isEditInfo === 0 ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="align-items-center justify-content-between">
                    <Col md="12">
                      <Form.Group className="form-group">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control mb-0"
                          id="phone"
                          placeholder="Enter Your Phone"
                          maxLength="10"
                          autoComplete="off"
                          value={this.state.phone}
                          onChange={this.handlePhone}
                          readOnly={this.state.isEditInfo === 0 ? true : false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container> */}

        <div className="my-profile">
          <div className="my-profile-one">
            <img
              src={require("../../assets/images/Rectangle.png")}
              className="my-profile-img"
              alt="bgimg"
            />
            <img
              src={require("../../assets/images/mobile-bg-img.png")}
              className="my-profilemobile-img"
              alt="bgimg"
            />
            <div className="sample-user">
              <div className="sample-user-content">
                <Profilevecor3 />
                <div className="profile-info">
                  <h3>
                    {" "}
                    {this.state.name}
                    {/* <Profilevecor2 className="sample-check" /> */}
                  </h3>
                  {/* <p>165 Posts • 22.9K Likes • 86.9K Members</p> */}
                </div>
              </div>
              {/* <Profilevecor1 className="profile-plus" /> */}
            </div>
            <div className="profile-image-set">
              <div className="image-profile">
                <img
                  // src={require("../../assets/images/profile-Ellipse.png")}
                  src={
                    this.state.profileImage !== ""
                      ? this.state.profileImage
                      : user
                  }
                  className="profile-Ellipse"
                  alt="profileimg"
                />
                <div className="profile-sampleuser">
                  <label className="p-image">
                    <input
                      accept="image/*"
                      type="file"
                      onChange={(e) => this.handleProfileImage(e)}
                      style={{ display: "none" }}
                    />

                    <Profilevecor className="profileedit-icon" />
                  </label>
                </div>
              </div>
              <button
                type="button"
                className="user-btn"
              >
                {">"} {this.state.name}
              </button>
            </div>
          </div>

          <div className="my-profile-second">
            <div className="user-setting">
              <h4>USER SETTINGS</h4>
              <Accordion
                className="user-accordion"
                id="first-accordionuser"
              >
                <AccordionSummary
                  className="user-accordionname"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <img
                    src={require("../../assets/images/acc-vect1.png")}
                    alt="vectorimg"
                  />
                  <p>Personal Details</p>
                </AccordionSummary>
                <AccordionDetails className="user-details">
                  <div className="user-info-fields">
                    <div className="user-info-field">
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="user-inputfield"
                          placeholder="Sample"
                          value={this.state.firstName}
                          onChange={this.handleFirstName}
                        />
                      </Form.Group>
                      {/* <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          type="text"
                          className="user-inputfield"
                          placeholder=">sample.user"
                        />
                      </Form.Group> */}
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Your Email</Form.Label>
                        <Form.Control
                          type="email"
                          className="user-inputfield"
                          placeholder="sample.user@xyz.com"
                          defaultValue={this.state.email}
                          readOnly={true}
                        />
                      </Form.Group>
                      <div className="country-code">
                        {/* <Form.Group>
                          <Form.Label>Country Code</Form.Label>
                          <Form.Control
                            type="text"
                            className="user-inputfield"
                            placeholder="US (+1)"
                            id="country-code"
                          />
                        </Form.Group> */}
                        <Form.Group id="contact-number">
                          <Form.Label>Contact Number</Form.Label>
                          <Form.Control
                            type="text"
                            className="user-inputfield"
                            placeholder="222 2222 222"
                            id="country-code-number"
                            maxLength="10"
                            autoComplete="off"
                            value={this.state.phone}
                            onChange={this.handlePhone}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div
                      className="user-info-field"
                      id="user-info-field"
                    >
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="user-inputfield"
                          placeholder="User"
                          value={this.state.lastName}
                          onChange={this.handleLastName}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Date of Birth</Form.Label>
                        <DatePicker
                          className="user-inputfield"
                          selected={this.state.dob}
                          dateFormat="MM/dd/yyyy"
                          maxDate={date}
                          onChange={this.handleDOB}
                          showYearDropdown
                          showMonthDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={100}
                          // readOnly={this.state.isEditInfo === 0 ? true : false}
                        />
                      </Form.Group>
                      {/* <div className="country-code">
                        <Form.Group>
                          <Form.Label>Country Code</Form.Label>
                          <Form.Control
                            type="text"
                            className="user-inputfield"
                            placeholder="US (+1)"
                            id="country-code"
                          />
                        </Form.Group>
                        <Form.Group id="contact-number">
                          <Form.Label>Contact Number</Form.Label>
                          <Form.Control
                            type="text"
                            className="user-inputfield"
                            placeholder="222 2222 222"
                            id="country-code-number"
                            maxLength="10"
                            autoComplete="off"
                            value={this.state.phone}
                            onChange={this.handlePhone}
                          />
                        </Form.Group>
                      </div> */}
                    </div>
                  </div>
                  <div className="savechanges-btn">
                    <button
                      type="button"
                      className="save-btn"
                      onClick={() => {
                        this.saveUserInfo()
                      }}
                      disabled={!this.isFieldsChanged()}
                    >
                      Save Changes
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* <Accordion className="user-accordion">
                <AccordionSummary
                  className="user-accordionname"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <img
                    src={require("../../assets/images/acc-vect2.png")}
                    alt="vectorimg"
                  />
                  <p>Socials</p>
                </AccordionSummary>
                <AccordionDetails
                  className="user-details"
                  id="social-accordion"
                >
                  <div className="user-social-icons">
                    <div className="user-socialicons-sub">
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon1 />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link instagram..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon2 className="web-twitter" />
                          <Socialicon11 className="mobile-twitter" />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link twitter..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon3 />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link facebook..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon4 />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link tiktok..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon5 />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link youtube..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div className="user-socialicons" id="user-socialicons">
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon6 />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link twitch..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon7 />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link spotify..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon8 />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link linkedin..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon9 />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link blogger..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="user-socialicons-subfiled">
                        <div className="user-socialicons-img">
                          <Socialicon10 />
                        </div>
                        <div className="user-socialicons-field">
                          <Form.Group>
                            <Form.Control
                              type="text"
                              className="sociallink-inputfield"
                              placeholder="Link external website..."
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="savechanges-btn">
                    <button type="button" className="save-btn">
                      Save Changes
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className="user-accordion" id="third-accordionvlink">
                <AccordionSummary
                  className="user-accordionname"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <div className="vlink-text">
                    <Vlink />
                    <p>V-Link</p>
                  </div>
                  <div className="toggle-switch">
                    <Switch />
                  </div>
                </AccordionSummary>
                <AccordionDetails className="user-details" id="vlink-accordion">
                  <div className="v-linkdetails">
                    <p className="v-linkdetailspara">
                      When enabled, you will be able to make a V-Link card with
                      the socials you want to be added there. You will get a
                      link which will be sharable with others. Through that
                      link, others can see your name, picture, bio, ways to
                      interact through Veuit and your socials.
                    </p>
                    <div className="vlink-qr">
                      <div className="copyvlink">
                        <p>https:/veuit.com/v-link/sample1user</p>
                        <button className="linkcopy-btn">
                          <Copybtn /> Copy
                        </button>
                      </div>
                      <Vlinkqr
                        className="vlinkqr-codemodal"
                        onClick={() =>
                          this.setState({ isVlinkQrmodalVisible: true })
                        }
                      />
                    </div>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>About / Bio</Form.Label>
                      <Form.Control as="textarea" className="about-field" />
                    </Form.Group>

                    <div className="social-display">
                      <div className="social-displaytext">
                        <img
                          src={require("../../assets/images/acc-vect2.png")}
                          alt="bgimg"
                        />
                        <p>Enable Socials for Display</p>
                      </div>
                      <div className="socialdisplay-icons">
                        <div className="social-displayicons">
                          <div className="social-linktoggle">
                            <Socialicon1 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                          <div className="social-linktoggle">
                            <Socialicon11 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                          <div className="social-linktoggle">
                            <Socialicon3 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                          <div className="social-linktoggle">
                            <Socialicon12 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch />
                            </div>
                          </div>
                          <div
                            className="social-linktoggle"
                            id="mobile-icons-links"
                          >
                            <Socialicon4 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                          <div
                            className="social-linktoggle"
                            id="mobile-icons-links"
                          >
                            <Socialicon5 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                        </div>
                        <div className="social-displayicons">
                          <div className="social-linktoggle">
                            <Socialicon6 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                          <div className="social-linktoggle">
                            <Socialicon7 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                          <div className="social-linktoggle">
                            <Socialicon8 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                          <div className="social-linktoggle">
                            <Socialicon13 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch />
                            </div>
                          </div>
                          <div
                            className="social-linktoggle"
                            id="mobile-icons-links"
                          >
                            <Socialicon9 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch />
                            </div>
                          </div>
                          <div
                            className="social-linktoggle"
                            id="mobile-icons-links"
                          >
                            <Socialicon10 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch />
                            </div>
                          </div>
                        </div>
                        <div
                          className="social-displayicons"
                          id="social-displayicons"
                        >
                          <div className="social-linktoggle">
                            <Socialicon4 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                          <div className="social-linktoggle">
                            <Socialicon5 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch checked />
                            </div>
                          </div>
                          <div className="social-linktoggle">
                            <Socialicon9 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch />
                            </div>
                          </div>
                          <div className="social-linktoggle">
                            <Socialicon10 className="icons-set" />
                            <div className="toggle-switch">
                              <Switch />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="social-displaytext">
                      <Theme />
                      <p>Themes</p>
                    </div>
                    <p className="social-detailspara">
                      User has a choice to select one theme out of the four
                      themes available to them. This will make their V-Links
                      card more attractive.
                    </p>

                    <div className="vlink-themes">
                      <div className="select-themes">
                        <img
                          src={require("../../assets/images/theme-1.png")}
                          alt="theme-img"
                          className="theme-img"
                        />
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="select-themes">
                        <img
                          src={require("../../assets/images/theme-2.png")}
                          alt="theme-img"
                          className="theme-img"
                        />
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="select-themes">
                        <img
                          src={require("../../assets/images/theme-3.png")}
                          alt="theme-img"
                          className="theme-img"
                        />
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="select-themes">
                        <img
                          src={require("../../assets/images/theme-4.png")}
                          alt="theme-img"
                          className="theme-img"
                        />
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="savechanges-btn">
                    <button type="button" className="save-btn">
                      Save Changes
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion> */}
            </div>

            {/* <div className="user-setting" id="way-tointeract-section">
              <h4>WAYS TO INTERACT</h4>
            </div>

            <div className="user-setting" id="priority-dm">
              <Accordion className="user-accordion">
                <AccordionSummary
                  className="user-accordionname"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Union />
                  <p>Priority DM</p>
                </AccordionSummary>
                <AccordionDetails className="user-details">
                  <div className="total-completebtns">
                    <button className="total-btns">Total: $420.77</button>
                    <button className="completed-btns">134 Completed</button>
                  </div>
                  <div className="priority-dm">
                    <p>Priority DM</p>
                    <div className="toggle-switch">
                      <Switch checked />
                    </div>
                  </div>
                  <p className="v-linkdetailspara">
                    When enabled, users will be allowed to pay for an
                    interactive video call with you. They will set up a meeting
                    within your preferred time frame, and if you accept, you
                    must go through with it to claim your payment. If you do not
                    , the user will not be charged.
                  </p>

                  <div className="response-time">
                    <div className="responsetime">
                      <p>Response Time</p>
                      <Dropdown id="time-dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          24 hours
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="responsetime" id="time-pannel">
                      <p>Price</p>
                      <div className="price-dropdown">
                        <Dropdown id="pricedropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            USD
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="usd-input">
                          <h4>$0.00 USD</h4>
                          <h5> Minimum price $0 USD or free</h5>
                        </div>
                      </div>
                      <h6> Minimum price $0 USD or free</h6>
                    </div>
                  </div>
                  <div className="savechanges-btn">
                    <button type="button" className="save-btn">
                      Save Changes
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="user-setting" id="face-toface">
              <Accordion className="user-accordion">
                <AccordionSummary
                  className="user-accordionname"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <Facevec />
                  <p>Face 2 Face</p>
                </AccordionSummary>
                <AccordionDetails className="user-details">
                  <div className="total-completebtns">
                    <button className="total-btns">Total: $420.77</button>
                    <button className="completed-btns">134 Completed</button>
                  </div>
                  <div className="priority-dm">
                    <p>Face 2 Face</p>
                    <div className="toggle-switch">
                      <Switch checked />
                    </div>
                  </div>
                  <p className="v-linkdetailspara">
                    When enabled, users will be allowed to pay for an
                    interactive video call with you. They will set up a meeting
                    within your preferred time frame, and if you accept, you
                    must go through with it to claim your payment. If you do not
                    , the user will not be charged.
                  </p>

                  <div className="response-time" id="web-response-metting">
                    <div className="responsetime" id="meeting-time">
                      <p>Meeting Time</p>
                    </div>
                    <div className="responsetime">
                      <p>Price</p>
                    </div>
                  </div>

                  <div className="meeting-time"  id="web-response-metting">
                    <div className="responsetime">
                      <Form.Control
                        type="text"
                        className="user-inputfield"
                        placeholder="15 minutes"
                      />
                    </div>
                    <div className="responsetime" id="price-pannel">
                      <div className="price-dropdown">
                        <Dropdown id="pricedropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            USD
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="usd-input">
                          <h4>$25.00 USD</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="meeting-time"  id="web-response-metting">
                    <div className="responsetime">
                      <Form.Control
                        type="text"
                        className="user-inputfield"
                        placeholder="30 minutes"
                      />
                    </div>
                    <div className="responsetime" id="price-pannel">
                      <div className="price-dropdown">
                        <Dropdown id="pricedropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            USD
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="usd-input">
                          <h4>$40.00 USD</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="meeting-time"  id="web-response-metting">
                    <div className="responsetime">
                      <Form.Control
                        type="text"
                        className="user-inputfield"
                        placeholder="60 minutes"
                      />
                    </div>
                    <div className="responsetime" id="price-pannel">
                      <div className="price-dropdown">
                        <Dropdown id="pricedropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            USD
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="usd-input">
                          <h4>$70.00 USD</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="button" className="add-slotsbtn">
                    <Addslot className="vec-adslot" /> Add more Slots
                  </button>
                  <div className="response-time" id="mobile-response-metting">
                    <div className="responsetime">
                      <p>Face 2 Face Time</p>
                      <Dropdown id="time-dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          30 minutes
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="responsetime" id="time-pannel">
                      <p>Price</p>
                      <div className="price-dropdown">
                        <Dropdown id="pricedropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            USD
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="usd-input">
                          <h4>$0.00 USD</h4>
                          <h5> Minimum price $0 USD or free</h5>
                        </div>
                      </div>
                    </div>
                    <div className="responsetime">
                      <p>Maximum Face 2 Face Time</p>
                      <Dropdown id="time-dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          60 minutes
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="priority-dm">
                    <p>Allow users to record video call</p>
                    <div className="toggle-switch" id="viedeo-callswitch">
                      <Switch checked />
                    </div>
                  </div>
                  <p className="v-linkdetailspara">
                    When enabled, users will be allowed to gain access to a
                    downloadable recording of your session for an additional
                    cost.
                  </p>
                  <div className="video-calltext">
                    <h6>Additional price per guest</h6>
                    <div className="usd-input">
                      <h4>$0.00 USD</h4>
                      <h5> Minimum price $0 USD or free</h5>
                    </div>
                  </div>

                  <div className="priority-dm">
                    <p>Allow users to extend Face 2 Face</p>
                    <div className="toggle-switch" id="face-twoswitch">
                      <Switch checked />
                    </div>
                  </div>

                  <div className="response-time">
                    <div className="responsetime">
                      <p>Time Extension</p>
                      <Dropdown id="time-dropdown">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          10 minutes
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">
                            Action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Another action
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Something else
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="responsetime" id="time-pannel">
                      <p>Price</p>
                      <div className="price-dropdown">
                        <Dropdown id="pricedropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            USD
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Another action
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-3">
                              Something else
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="usd-input">
                          <h4>$0.00 USD</h4>
                          <h5> Minimum price $0 USD or free</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="savechanges-btn">
                    <button type="button" className="save-btn">
                      Save Changes
                    </button>
                  </div>

                  <div className="faceto-schedule">
                    <div className="faceto-scheduletext">
                      <h4>SCHEDULE</h4>
                      <p>View scheduled lives</p>
                    </div>
                    <div className="calender-schedule">
                      <div className="schedule-charge-details">
                        <div className="responsetime">
                          <p>Additional charge price</p>
                          <Dropdown id="time-dropdown">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              Monday - Friday
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="schedule-tiomezone">
                          <Dropdown id="time-dropdown">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              9 am (PST)
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <h2>to:</h2>
                          <Dropdown id="time-dropdown">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              5 pm (PST)
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="responsetime">
                          <p>Intervals between calls</p>
                          <Dropdown id="time-dropdown">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              15 minutes
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <h6>
                          Tip: By clicking the dates on the calendar you may
                          cancel meetings, see when they are scheduled, or add /
                          remove a day of availability.
                        </h6>
                      </div>
                      <div id="calender-schedule-picker">
                        <div>
                          <Calendar
                            onChange={this.onChangeCalendar}
                            value={this.state.value}
                            calendarType="US"
                            formatShortWeekday={(locale, date) => {
                              const shortDays = [
                                "Su",
                                "Mo",
                                "Tu",
                                "We",
                                "Th",
                                "Fr",
                                "Sa",
                              ];
                              const dayIndex = (date.getDay() + 7) % 7;
                              return shortDays[dayIndex];
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="advanced-options">
                    <div className="options-text">
                      <h4>Advanced Options</h4>
                      <Downvec />
                    </div>
                    <div className="range-datetext">
                      <p>Set a range of dates when you can accept meetings.</p>
                    </div>
                    <div className="existing-schedule">
                      <div className="existing-schedule-btn">
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                        <h4>Use an existing schedule</h4>
                      </div>
                      <div
                        className="existing-schedule-btn"
                        id="existing-schedule-btn"
                      >
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio"  />
                          <span className="checkmark"></span>
                        </label>
                        <h4 className="custom-hours">Set custom hours</h4>
                      </div>
                    </div>
                    <div className="weekly-options">
                      <p className="weekly-text">Weekly Hours</p>
                      <div className="existing-schedule-btn">
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                        <h5>Sun</h5>
                        <p>Unavailable</p>
                      </div>
                      <div className="existing-schedule-btn">
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                        <h5>Mon</h5>
                        <button
                          type="button"
                          className="delete-schedule-btn"
                          id="delete-schedule-btn"
                        >
                          11:00am
                        </button>
                        <span className="space">-</span>
                        <button type="button" className="delete-schedule-btn">
                          06:00pm
                        </button>
                        <Deletevec className="delete-scheduleimg" />
                      </div>
                      <div className="existing-schedule-btn">
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                        <h5>Tue</h5>
                        <button
                          type="button"
                          className="delete-schedule-btn"
                          id="delete-schedule-btn"
                        >
                          11:00am
                        </button>
                        <span className="space">-</span>
                        <button type="button" className="delete-schedule-btn">
                          06:00pm
                        </button>
                        <Deletevec className="delete-scheduleimg" />
                      </div>
                      <div className="existing-schedule-btn">
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                        <h5>Wed</h5>
                        <button
                          type="button"
                          className="delete-schedule-btn"
                          id="delete-schedule-btn"
                        >
                          11:00am
                        </button>
                        <span className="space">-</span>
                        <button type="button" className="delete-schedule-btn">
                          06:00pm
                        </button>
                        <Deletevec className="delete-scheduleimg" />
                      </div>
                      <div className="existing-schedule-btn">
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                        <h5>Thu</h5>
                        <button
                          type="button"
                          className="delete-schedule-btn"
                          id="delete-schedule-btn"
                        >
                          11:00am
                        </button>
                        <span className="space">-</span>
                        <button type="button" className="delete-schedule-btn">
                          06:00pm
                        </button>
                        <Deletevec className="delete-scheduleimg" />
                      </div>
                      <div className="existing-schedule-btn">
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                        <h5>Fri</h5>
                        <button
                          type="button"
                          className="delete-schedule-btn"
                          id="delete-schedule-btn"
                        >
                          11:00am
                        </button>
                        <span className="space">-</span>
                        <button type="button" className="delete-schedule-btn">
                          06:00pm
                        </button>
                        <Deletevec className="delete-scheduleimg" />
                      </div>
                      <div className="existing-schedule-btn">
                        <label className="theme-selectbtn">
                          <input type="radio" name="radio" />
                          <span className="checkmark"></span>
                        </label>
                        <h5>Sat</h5>
                        <p>Unavailable</p>
                      </div>
                    </div>
                    <div class="savechanges-btn">
                      <button type="button" class="save-btn">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="user-setting" id="Experiences">
              <Accordion className="user-accordion">
                <AccordionSummary
                  className="user-accordionname"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  <div className="experience-texts">
                    <img
                      src={require("../../assets/images/Popularman.png")}
                      alt="vectorimg"
                    />
                    <h4>Experiences</h4>
                    <div className="toggle-switch">
                      <Switch checked />
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="user-details">
                  <p>
                    When enabled, users will be allowed to pay for a experience
                    on your feed. They are allowed to choose any experience you
                    have posted on your feed. If you accept and don’t complete
                    the transaction, they will not be charged.
                  </p>
                  <button type="button" class="Customize-btn">
                    Customize
                  </button>
                </AccordionDetails>
              </Accordion>
            </div> */}
          </div>
        </div>

        <VlinkQrmodal
          show={this.state.isVlinkQrmodalVisible}
          hideModal={this.hideConfirmModel}
        />
      </section>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserProfile)
)
