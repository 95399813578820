import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import ReactJWPlayer from "react-jw-player";

import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { selectEmployeeData } from "../../store/home/home.selectors";
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const mapStateToProps = (state) => {
  return { employeeData: selectEmployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trailerPlay: false,
      originalSeriesMap: props.originalSeriesMap,
      isLogin: false,
      isShowModel: false,
      trailerUrl: "",
    };
  }

  componentDidMount() {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      this.setState({ isLogin: true });
    } else {
      this.setState({ isLogin: false });
    }
  }

  playTrailer = (trailerUrl) => {
    this.props.playTrailer(trailerUrl);
  };

  handleClose = () => {
    this.setState({ isShowModel: false, trailerUrl: "" });
  };

  handleShow = (trailerUrl) => {
    this.setState({ isShowModel: true, trailerUrl: trailerUrl });
  };

  render() {
    return (
      <section id="home">
        {/* fullscreen={true} */}
        <Modal
          show={this.state.isShowModel}
          centered
          dialogClassName="modal-fullscreen"
          id="app-modal"
        >
          <div id="modal-content-wrapper">
            <Modal.Header
              closeButton
              onHide={this.handleClose}
              backdrop="static"
            ></Modal.Header>
            <Modal.Body>
              <ReactJWPlayer
                playerId="BannerSeriesTrailer"
                playerScript="https://cdn.jwplayer.com/libraries/zFFedrqG.js"
                file={this.state.trailerUrl}
                isAutoPlay={true}
                // aspectRatio="20:9"
                customProps={{
                  playbackRateControls: [1, 1.25, 1.5],
                  cast: {},
                }}
              />
            </Modal.Body>
          </div>
        </Modal>

        {this.state.originalSeriesMap.bannerVideoUrl != null &&
        this.state.originalSeriesMap.bannerVideoUrl !== "" ? (
          <>
            <div className="d-video-banner-par" style={{ display: "flex" }}>
              <video playsInline autoPlay loop muted className="d-video-banner">
                <source
                  src={this.state.originalSeriesMap.bannerVideoUrl}
                  type="video/mp4"
                />
              </video>
            </div>
            <div
              className="position-absolute h-100 d-vedio-border"
              style={{ left: 0, top: 0, bottom: 0, right: 0 }}
            >
              <div className="slider-inner  h-100">
                <Row className="align-items-center justify-content-center  h-100">
                  <Col className="r-mb-23">
                    <div className="text-center d-banner-space">
                      {this.state.originalSeriesMap.isBannerTitle != null &&
                      this.state.originalSeriesMap.isBannerTitle !== null &&
                      this.state.originalSeriesMap.isBannerTitle === 1 ? (
                        <p className="slider-text d-slide-txt">
                          {this.state.originalSeriesMap.bannerTitle != null &&
                          this.state.originalSeriesMap.bannerTitle != ""
                            ? this.state.originalSeriesMap.bannerTitle
                            : " "}
                        </p>
                      ) : (
                        ""
                      )}
                      <h1 className="slider-text text-uppercase">
                        {this.state.originalSeriesMap.seriesName}
                      </h1>
                      <div className="parallax-buttons d-bnr-btn">
                        {/* {this.state.isLogin ?
                                                    <> */}
                        {this.state.originalSeriesMap.isEpisodeData != null &&
                        this.state.originalSeriesMap.isEpisodeData !== null &&
                        this.state.originalSeriesMap.isEpisodeData === 1 ? (
                          <Link
                            to={`/series-details/${this.state.originalSeriesMap.seriesId}`}
                            className="btn d-btn"
                          >
                            VIEW NOW
                          </Link>
                        ) : (
                          <Link to="#" className="btn d-btn">
                            Coming Soon..
                          </Link>
                        )}
                        {/* </>
                                                    :
                                                    <> {this.state.originalSeriesMap.trailerUrl != null && this.state.originalSeriesMap.trailerUrl !== '' ?
                                                        <Link to="#" className="btn d-btn" onClick={this.handleShow.bind(this, this.state.originalSeriesMap.trailerUrl)}>VIEW TRAILER</Link>
                                                        : ''}
                                                    </>
                                                } */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              {/* <hr className='set-gradient-border'></hr> */}
              <div style={{ position: "relative", display: "flex" }}>
                {this.state.originalSeriesMap.bannerImageUrl != null &&
                  this.state.originalSeriesMap.bannerImageUrl !== "" && (
                    <img
                      className="d-video-banner"
                      src={this.state.originalSeriesMap.bannerImageUrl}
                      style={{ height: "100%", width: "100%" }}
                      alt={this.state.originalSeriesMap.seriesName}
                    />
                  )}
              </div>
              {/* <hr className='set-gradient-border'></hr> */}
            </div>
            <div
              className="position-absolute h-100"
              style={{ left: 0, top: 0, bottom: 0, right: 0 }}
            >
              <div className="slider-inner h-100">
                <Row className="align-items-center justify-content-center h-100 ">
                  <Col className="r-mb-23">
                    <div className="text-center">
                      {this.state.originalSeriesMap.isBannerTitle != null &&
                      this.state.originalSeriesMap.isBannerTitle !== null &&
                      this.state.originalSeriesMap.isBannerTitle === 1 ? (
                        <p className="slider-text d-slide-txt">
                          {this.state.originalSeriesMap.bannerTitle != null &&
                          this.state.originalSeriesMap.bannerTitle != ""
                            ? this.state.originalSeriesMap.bannerTitle
                            : " "}
                        </p>
                      ) : (
                        ""
                      )}
                      <h1 className="slider-text big-title title text-uppercase">
                        {this.state.originalSeriesMap.seriesName}
                      </h1>
                      <div className="parallax-buttons">
                        {/* {this.state.isLogin ?
                                                    <> */}
                        {this.state.originalSeriesMap.isEpisodeData != null &&
                        this.state.originalSeriesMap.isEpisodeData !== null &&
                        this.state.originalSeriesMap.isEpisodeData === 1 ? (
                          <Link
                            to={`/series-details/${this.state.originalSeriesMap.seriesId}`}
                            className="btn d-btn"
                          >
                            VIEW NOW
                          </Link>
                        ) : (
                          <Link to="#" className="btn d-btn">
                            Coming Soon..
                          </Link>
                        )}
                        {/* </>
                                                    :
                                                    <> {this.state.originalSeriesMap.trailerUrl != null && this.state.originalSeriesMap.trailerUrl !== '' ?
                                                        <Link to="#" className="btn d-btn" onClick={this.handleShow.bind(this, this.state.originalSeriesMap.trailerUrl)}>VIEW TRAILER</Link>
                                                        : ''}
                                                    </>
                                                } */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
