import React from "react";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactComponent as Purchasesearch } from "../../assets/images/purchase-search.svg";
import { ReactComponent as Purchaseright } from "../../assets/images/purchase-right.svg";
import { ReactComponent as Purchasefilter } from "../../assets/images/purchase-filter.svg";
import { NotificationManager } from "react-notifications";
import { selectPurchaseSeriesListStatus } from "../../store/PurchaseSeriesList/PurchaseSeriesList.selectors";
import { purchaseSeriesListStart } from "../../store/PurchaseSeriesList/PurchaseSeriesList.actions";
import AppConfig from "../../config/app.config";
import Backdrop from "@material-ui/core/Backdrop";
import {
  selectEmployeeData,
  selectMoviesCategory,
} from "../../store/home/home.selectors";
import { moviesCategorySyncStart } from "../../store/home/home.actions";
import PurchaseList from "../../components/purchase-list/purchase-list.component";
import MovieList from "../../components/movie-list/movie-list.component";
import Loader from "../../components/Loader";

const mapStateToProps = (state) => {
  return {
    selectPurchaseSeriesListStatus: selectPurchaseSeriesListStatus(state),
    employeeData: selectEmployeeData(state),
    selectMoviesCategory: selectMoviesCategory(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      purchaseSeriesListStart,
      moviesCategorySyncStart,
    },
    dispatch
  ),
});
class Purchases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseSeriesList: null,
      isLoading: 0,
      searchResult: "",
      showsList: [],
      moviesMap: [],
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.purchaseSeriesList = null;
    this.moviesList = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.employeeData === null) {
      this.props.history.push("/");
    } else {
      this.setState({ isLoading: 1 });

      this.props.purchaseSeriesListStart();

      const { moviesCategorySyncStart } = this.props;

      const payload = {
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        employeeId: this.props.employeeData?.employeeId,
        loginEmployeeId: this.props.employeeData?.employeeId,
        appId: AppConfig.API.APP_ID,
        timeZone: null,
        isPurchase: 1,
      };

      moviesCategorySyncStart(payload);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectPurchaseSeriesListStatus, selectMoviesCategory } = this.props;
    if (selectPurchaseSeriesListStatus.error !== null) {
      NotificationManager.error(selectPurchaseSeriesListStatus.error, "", 2000);
      this.setState({ isLoading: 0 });
    }

    if (
      selectPurchaseSeriesListStatus.seriesList !== null &&
      selectPurchaseSeriesListStatus.seriesList !==
        prevProps.selectPurchaseSeriesListStatus.seriesList
    ) {
      this.setState({
        isLoading: 0,
        showsList: selectPurchaseSeriesListStatus.seriesList,
      });
      this.purchaseSeriesList = selectPurchaseSeriesListStatus.seriesList;
    }

    if (
      selectMoviesCategory.moviesCategoryList !== null &&
      selectMoviesCategory.moviesCategoryList !==
        prevProps.selectMoviesCategory.moviesCategoryList
    ) {
      this.setState({
        moviesMap: selectMoviesCategory.moviesCategoryList,
      });
    } else {
      const payload = {
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        employeeId: this.props.employeeData?.employeeId,
        loginEmployeeId: this.props.employeeData?.employeeId,
        appId: AppConfig.API.APP_ID,
        timeZone: null,
        isPurchase: 1,
      };
      moviesCategorySyncStart(payload);
    }
  }

  handleSearch(event) {
    this.setState({
      showsList:
        this.purchaseSeriesList != null
          ? this.purchaseSeriesList.filter((item) =>
              item.seriesName
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
            )
          : [],
      moviesMap:
        this.moviesList != null
          ? this.moviesList.filter((item) =>
              item.movieName
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
            )
          : [],
      searchResult: event.target.value,
    });
  }

  render() {
    return (
      <div className="purchases-page">
        {this.state.isLoading === 1 ? <Loader /> : ""}
        <div className="purchase-container">
          <div className="searchbar-products">
            <div className="purchase-text">
              <span
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <Purchaseright className="arrow-right" />
              </span>
              <h4>Purchases</h4>
            </div>
            <div className="shows-search">
              <div className="shows-filter">
                <Purchasesearch className="search-icon" />
                <Form.Group
                  className="mb-0"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="Search"
                    className="search-field"
                    placeholder="Search"
                    value={this.state.searchResult}
                    onChange={this.handleSearch}
                  />
                </Form.Group>
              </div>
              <button type="button" className="filter-btn">
                <Purchasefilter className="filter-icon" />
                <span>Filter</span>
              </button>
            </div>
          </div>

          {this.state.showsList != null && this.state.showsList.length > 0 ? (
            <div className="shows-lists">
              <div className="shows-name">
                <h4>Shows</h4>
                {/* <p>View More {">"} </p> */}
              </div>
              <div className="show-listsection">
                {this.state.showsList &&
                  this.state.showsList.map((item, num) => (
                    <div
                      className="show-listsub-section"
                      key={`SHOWS-${num}`}
                      onClick={() => {
                        this.props.history.push(
                          `/purchases-details/${item.seriesId}`
                        );
                      }}
                    >
                      <img
                        src={item.bannerUrl}
                        className="show-img"
                        alt="img"
                      />
                      <div className="image-tags">
                        <h4>{item.seriesName}</h4>
                        <h5>
                          {item.seasons
                            .map((i) => i.episodes.length)
                            .reduce((prev, curr) => prev + curr, 0)}{" "}
                          Episodes
                        </h5>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div className="shows-lists">
              <div className="shows-name">
                <h4>Shows</h4>
                {/* <p>View More {">"} </p> */}
              </div>
              <div className="show-listsection">
                <h4>No Shows Found</h4>
              </div>
            </div>
          )}

          {this.state.moviesMap != null && this.state.moviesMap.length > 0 ? (
            <>
              {this.state.moviesMap?.map((item, key) => {
                return (
                  <PurchaseList
                    dataMap={item.moviesCategoryMap}
                    tagName={item.moviesCategoryName}
                    id={"purchase" + key + "-" + item.moviesCategoryId}
                    viewAllUrl={"/view-all/" + item.moviesCategoryId}
                    playNowUrl="/movie-details"
                  />

                  // <MovieList
                  //   key={key}
                  //   moviesMap={item.moviesCategoryMap}
                  //   id={"purchase" + key + "-" + item.moviesCategoryId}
                  //   tagName={item.moviesCategoryName}
                  //   playNowUrl="/movie-details"
                  //   viewAllUrl={"/view-all/" + item.moviesCategoryId}
                  //   addToWatchList={this.addToWatchList}
                  // />
                );
              })}
            </>
          ) : (
            ""
          )}

          {/* <div className="shows-lists">
            <div className="shows-name">
              <h4>Subscriptions</h4>
              <p>View More {">"} </p>
            </div>
            <div className="Subscription-list">
              <div className="Subscription-listsection">
                <img
                  src={require("../../assets/images/The-Count.png")}
                  className="Subscription-img"
                  alt="img"
                />
                <div className="Subscription-details">
                  <img
                    src={require("../../assets/images/Stranger Things.png")}
                    className="Subscription-image"
                    alt="img"
                  />
                  <h4>Gordon Ramsay</h4>
                  <h5>{">"}mrchefman</h5>
                  <p>212K Members</p>
                </div>
              </div>
            </div>
          </div>

          <div className="shows-lists">
            <div className="shows-name">
              <h4>Live Events</h4>
              <p>View More {">"} </p>
            </div>
            <div className="show-listsection">
              <div className="show-listsub-section">
                <img
                  src={require("../../assets/images/The-Count.png")}
                  className="show-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
              <div className="show-listsub-section">
                <img
                  src={require("../../assets/images/Stranger Things.png")}
                  className="show-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
              <div className="show-listsub-section">
                <img
                  src={require("../../assets/images/The-Count.png")}
                  className="show-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
              <div className="show-listsub-section">
                <img
                  src={require("../../assets/images/Stranger Things.png")}
                  className="show-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="shows-lists">
            <div className="shows-name">
              <h4>Merch</h4>
              <p>View More {">"} </p>
            </div>
            <div className="show-listsection">
              <div className="show-listsub-section" id="show-merchsub-section">
                <img
                  src={require("../../assets/images/The-Count.png")}
                  className="merch-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
              <div className="show-listsub-section" id="show-merchsub-section">
                <img
                  src={require("../../assets/images/The-Count.png")}
                  className="merch-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
              <div className="show-listsub-section" id="show-merchsub-section">
                <img
                  src={require("../../assets/images/The-Count.png")}
                  className="merch-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
              <div className="show-listsub-section" id="show-merchsub-section">
                <img
                  src={require("../../assets/images/The-Count.png")}
                  className="merch-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
              <div className="show-listsub-section" id="show-merchsub-section">
                <img
                  src={require("../../assets/images/The-Count.png")}
                  className="merch-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
              <div className="show-listsub-section" id="show-merchsub-section">
                <img
                  src={require("../../assets/images/The-Count.png")}
                  className="merch-img"
                  alt="img"
                />
                <div className="images-name">
                  <p>Shoutout on Instagram Story</p>
                  <div className="image-tags" id="image-tags">
                    <div className="image-ownername">
                      <img
                        src={require("../../assets/images/Frame1.png")}
                        className="profile-img"
                        alt="img"
                      />
                      <h4>The Count</h4>
                    </div>
                    <h5>$ 150.00</h5>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Purchases);
