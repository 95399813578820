import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Billingvec } from "../../assets/images/billing-vec.svg";
//import { ReactComponent as Placeorder } from "../../assets/images/place-order.svg";
import { selectEmployeeData } from "../../store/home/home.selectors";

const mapStateToProps = (state) => {
  return { employeeData: selectEmployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

class PaymentOrderConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddAddressModalVisible: false,
    };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.hideModal}
        backdrop="static"
        keyboard={false}
        centered
        id="billing-address-modal"
      >
        <div id="address-modal">
          <div className="order-confirmation">
            <Modal.Header closeButton>
              <div>
                <h4>Order Confirmation</h4>
              </div>
            </Modal.Header>
          </div>
          <div className="order-place">
            <div className="order-place1">
              <div className="order-place-image">
                <img
                  src={require("../../assets/images/order-place-right.png")}
                  alt="img"
                  className="orderplace-image"
                ></img>
                {/* <Placeorder className="orderplace-image" />  */}
              </div>
              <div className="order-place-text">
                <h4>
                  Hello{" "}
                  {this.props.employeeData != null &&
                  this.props.employeeData?.name != null
                    ? this.props.employeeData?.name
                    : ""}
                  ,
                </h4>
                <h5>Your order has been placed!</h5>
                <p>
                  Thanks for shopping! Confirmation will be sent to your email
                  shortly.
                </p>
              </div>
            </div>
            <p className="confirm-msg">
              Thanks for shopping! Confirmation will be sent to your email
              shortly.
            </p>
          </div>
          <div className="order-billing-address">
            <div className="order-billing-image">
              <Billingvec />
            </div>
            <div className="order-billing-text">
              <h4>Transaction Number</h4>
              <p>
                {this.props.transactionId != null
                  ? this.props.transactionId
                  : "*****"}
              </p>
            </div>
          </div>

          <div className="addreview-btn">
            <button className="review-order-btn" onClick={this.props.hideModal}>
              Thanks for shopping!
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentOrderConfirmModal);
