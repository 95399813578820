import React from "react";
import { Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  selectEmployeeData,
  selectLocation,
} from "../../store/home/home.selectors";
import AboutModal from "../about/about";

const mapStateToProps = (state) => {
  return {
    selectLocation: selectLocation(state),
    employeeData: selectEmployeeData(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class FooterManu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routeName: "",
      isAboutModalVisible: false,
    };
  }
  componentDidMount() {
    this.setState({ routeName: window.location.pathname });
  }
  componentDidUpdate(prevState, prevProps) {
    if (
      this.props.selectLocation.location !== null &&
      this.props.selectLocation.location !== undefined &&
      this.state.routeName !== this.props.selectLocation.location.pathname
    ) {
      this.setState({ routeName: this.props.selectLocation.location.pathname });
    }
  }

  render() {
    return (
      <footer
        id="contact"
        className={`footer-one iq-bg-dark ${
          this.state.routeName?.split("/")[1] === "podscast-details"
            ? "footer-podscast"
            : ""
        }`}
        style={{ zIndex: 1 }}
      >
        <div
          className="footer-top"
          style={{ paddingTop: "72px", paddingBottom: "80px" }}
        >
          <Container fluid id="footer-container">
            <Row className="footer-standard">
              <div className="set-app-footer">
                <div>
                  <Link to="/">
                    {" "}
                    <img
                      src={require("../../assets/images/ciright-studio-blue.png")}
                      className="c-logo"
                    />
                  </Link>
                </div>
                <div className="widget text-left">
                  <div
                    className="menu-footer-link-1-container"
                    id="set-list-footer"
                  >
                    <ul id="menu-footer-link-1" className="menu p-0">
                      <li
                        id="menu-item-7314"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314"
                      >
                        <Link to="/terms-of-use">
                          {/* <Link to="#"> */}
                          <h6
                            style={{ color: "#003087" }}
                            className="footer-list-text"
                          >
                            Terms Of Use
                          </h6>
                        </Link>
                      </li>
                      <li
                        id="menu-item-7316"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316"
                      >
                        <Link to="/privacy-policy">
                          {/* <Link to="#"> */}
                          <h6
                            style={{ color: "#003087" }}
                            className="footer-list-text"
                          >
                            Privacy Policy
                          </h6>
                        </Link>
                      </li>
                      <li
                        id="menu-item-7118"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118 faq-list"
                      >
                        <Link to="/faq">
                          <h6
                            style={{ color: "#003087" }}
                            className="footer-list-text"
                          >
                            FAQ
                          </h6>
                        </Link>
                      </li>
                      <li
                        id="menu-item-7118"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118 faq-list"
                      >
                        <Link to="/mission-purpose">
                          {/* <Link to="#"> */}
                          <h6
                            style={{ color: "#003087" }}
                            className="footer-list-text"
                          >
                            Mission and Purpose
                          </h6>
                        </Link>
                      </li>
                      {this.props.employeeData != null && (
                        <li
                          id="menu-item-7118"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118 faq-list"
                        >
                          <h6
                            style={{ color: "#003087" }}
                            className="footer-list-text"
                            // onClick={() =>
                            //   this.setState({ isAboutModalVisible: true })
                            // }
                          >
                            About
                          </h6>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <ul className="info-share">
                  <li>
                    <Link
                       target="_blank"
                       // to={"#"}
                       to={{ pathname: "https://www.instagram.com/cirightindia/" }}
                     >
                       <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="widget text-left">
                <div className="textwidget">
                  <p>
                    2024 CIRIGHT STUDIO. All Rights Reserved. All videos and
                    shows on this platform are trademarks of, and all related
                    images and content are the property of, Ciright Studio.
                    Duplication and copy of this is strictly prohibited.
                  </p>
                </div>
              </div>
              <ul className="info-share-mob">
                <Link
                  target="_blank"
                  // to={"#"}
                  to={{ pathname: "https://www.instagram.com/cirightindia/" }}
                >
                  <i className="fa fa-instagram"></i>
                </Link>
              </ul>
            </Row>
          </Container>
        </div>
        {this.state.isAboutModalVisible && (
          <AboutModal
            visible={this.state.isAboutModalVisible}
            close={() => this.setState({ isAboutModalVisible: false })}
          />
        )}
      </footer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterManu);
