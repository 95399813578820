import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import UserService from "../../services/user/user.service"
import { NotificationManager } from "react-notifications"
import "react-notifications/lib/notifications.css"
import Backdrop from "@material-ui/core/Backdrop"
import AppConfig from "../../config/app.config"
import { Link } from "react-router-dom"
import { secondsToHourse } from "../../helpers/core.helper"
import { withRouter } from "react-router-dom"
import {
  selectEmployeeData,
  selectBrands,
  selectMoviesCategory,
} from "../../store/home/home.selectors"
import {
  brandsSyncStart,
  moviesCategorySyncStart,
  setSigninRedirectPath,
} from "../../store/home/home.actions"
import Error from "../Error/Error"
import Loader from "../../components/Loader"

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
    selectBrands: selectBrands(state),
    selectMoviesCategory: selectMoviesCategory(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      brandsSyncStart,
      moviesCategorySyncStart,
      setSigninRedirectPath,
    },
    dispatch
  ),
})

export class ViewAll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: 2,
      dataType: "",
      dataTypeName: "",
      dataTypeId: "",
      dataMap: [],
      checkingUpdate: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const {
      brandsSyncStart,
      selectBrands,
      selectMoviesCategory,
      moviesCategorySyncStart,
    } = this.props

    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      employeeId: this.props.employeeData?.employeeId,
      loginEmployeeId: this.props.employeeData?.employeeId,
      appId: AppConfig.API.APP_ID,
    }

    const pathArray = window.location.pathname.split("/")
    if (pathArray[2] !== null && pathArray[2] !== "") {
      this.setState({ dataType: pathArray[2] })
      if (pathArray[2] === "brands") {
        this.setState({ dataTypeName: "Brands" })
        if (selectBrands.initialSynced && selectBrands.brandsList !== null) {
          this.setState({ dataMap: selectBrands.brandsList })
        } else {
          brandsSyncStart(payload)
        }
      } else {
        if (
          selectMoviesCategory.initialSynced &&
          selectMoviesCategory.moviesCategoryList !== null
        ) {
          const resultList = selectMoviesCategory.moviesCategoryList
          if (resultList != null && resultList.length > 0) {
            for (let i = 0; i < resultList.length; i++) {
              const row = resultList[i]
              const var1 = row.moviesCategoryId
              const var2 = pathArray[2]
              if (var1.toString() === var2.toString()) {
                this.setState({
                  dataTypeId: row.moviesCategoryId,
                  dataTypeName: row.moviesCategoryName,
                  dataMap: row.moviesCategoryMap,
                })
              }
            }
          } else {
            this.props.history.goBack()
          }
        } else {
          this.setState({ dataTypeId: pathArray[2] })
          moviesCategorySyncStart(payload)
        }
      }

      this.interval = setInterval(() => this.setState({ isLoading: 0 }), 5000)
    } else {
      this.props.history.goBack()
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectBrands, selectMoviesCategory } = this.props

    if (this.state.dataType === "brands") {
      if (selectBrands.initialSynced) {
        if (prevProps.selectBrands.brandsList !== selectBrands.brandsList) {
          this.setState({ dataMap: selectBrands.brandsList })
        }
      }
    } else {
      if (selectMoviesCategory.initialSynced) {
        if (
          prevProps.selectMoviesCategory.moviesCategoryList !==
          selectMoviesCategory.moviesCategoryList
        ) {
          const resultList = selectMoviesCategory.moviesCategoryList
          if (resultList != null && resultList.length > 0) {
            for (let i = 0; i < resultList.length; i++) {
              const row = resultList[i]
              const var1 = row.moviesCategoryId
              const var2 = this.state.dataTypeId
              if (var1.toString() === var2.toString()) {
                this.setState({
                  dataTypeName: row.moviesCategoryName,
                  dataMap: row.moviesCategoryMap,
                })
              }
            }
          }
        }
      }
    }
  }

  addToWatchList = (movieId, movieWatchListId, tagName) => {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId != null
    ) {
      this.setState({ isLoading: 1 })
      const payload = {
        movieId: movieId,
        employeeId: this.props.employeeData?.employeeId,
        movieWatchListId: movieWatchListId,
      }

      UserService.addToWatchList(payload)
        .then((res) => {
          if (res.error != null) {
            this.setState({ isLoading: 0 })
            NotificationManager.warning(
              res.error.response.data.message,
              "",
              2000
            )
          } else {
            if (res["status"]) {
              const payloadData = {
                subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
                verticalId: AppConfig.API.VERTICAL_ID,
                employeeId: this.props.employeeData?.employeeId,
                loginEmployeeId: this.props.employeeData?.employeeId,
              }

              this.props.moviesCategorySyncStart(payloadData)

              if (movieWatchListId !== -1) {
                NotificationManager.success(
                  "Movie Removed from My Watchlist.",
                  "",
                  2000
                )
              } else {
                NotificationManager.success(
                  "Movie Added to My Watchlist.",
                  "",
                  2000
                )
              }
              this.setState({ isLoading: 0 })
            } else {
              this.setState({ isLoading: 0 })
              NotificationManager.error(res["message"], "", 2000)
            }
          }
        })
        .catch((err) => {
          this.setState({ isLoading: 0 })
          NotificationManager.error(err, "", 2000)
        })
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      })
      this.props.history.push("/authentication/sign-in")
    }
  }

  render() {
    return (
      <section
        className="iq-main-slider p-0 iq-rtl-direction"
        style={{ position: "unset" }}
        id="iq-main-slider"
      >
        {this.state.isLoading === 1 ? <Loader /> : ""}
        {this.state.isLoading === 2 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: window.innerHeight - 100,
                width: "100%",
              }}
            >
              <img
                src={require("../../assets/images/Spinner.gif")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </div>
          </>
        ) : this.state.dataMap == null || this.state.dataMap.length === 0 ? (
          <Error />
        ) : (
          <>
            {/* <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{ backgroundImage: `url(${bannerImage})`, height: '50vh' }}>
                        </div> */}
            <div
              className="main-content"
              id="set-header-line"
            >
              <section id="iq-favorites">
                <Container fluid>
                  <h3
                    className="text-uppercase view-all-text-movie"
                    style={{ textAlign: "center", color: "#003087" }}
                  >
                    {this.state.dataTypeName}
                  </h3>
                  <Row
                    sm="12"
                    className="overflow-hidden"
                    style={{ paddingLeft: "60px", paddingRight: "60px" }}
                    id="viewall-row"
                  >
                    {this.state.dataMap != null &&
                      this.state.dataMap.map((item, key) => {
                        return (
                          <Col
                            lg="3"
                            sm="4"
                            md="3"
                            xl="2"
                            key={key}
                            style={{
                              paddingTop: "20px",
                              paddingBottom: "20px",
                            }}
                            id="view-all-movie"
                          >
                            <div className="swiper-slide">
                              <div className="block-images position-relative">
                                <>
                                  <div className="img-box">
                                    <img
                                      src={
                                        AppConfig.API.MANU_IMAGE +
                                        item.movieId +
                                        "&random=" +
                                        Date.now()
                                      }
                                      className="img-fluid"
                                      id="viewall-images"
                                      alt=""
                                    />
                                  </div>
                                  <div className="block-description block-social-info">
                                    <h5 className="iq-title">
                                      {item.movieName}{" "}
                                    </h5>
                                    <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                      {item.rating != null &&
                                      item.rating !== "" ? (
                                        <div className="badge badge-secondary p-1 mr-2">
                                          {item.rating}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <span className="text-white">
                                        {" "}
                                        {secondsToHourse(item.movieLength)}{" "}
                                      </span>
                                    </div>
                                    <div className="hover-buttons">
                                      <Link
                                        to={`/movie-details/${item.movieId}`}
                                        role="button"
                                        className="btn iq-button2"
                                        id="d-play-btn"
                                      >
                                        <i
                                          className="fa fa-play mr-1"
                                          aria-hidden="true"
                                        ></i>
                                        Play Now
                                      </Link>
                                    </div>
                                  </div>
                                  <div
                                    className="block-social-info"
                                    style={{ height: "50px" }}
                                  >
                                    <ul className="list-inline p-0 m-0 music-play-lists">
                                      <li
                                        onClick={this.addToWatchList.bind(
                                          this,
                                          item.movieId,
                                          item.movieWatchListId
                                        )}
                                      >
                                        <span
                                          style={{
                                            background:
                                              item.movieWatchListId !== -1
                                                ? "#003087"
                                                : "#ffffff",
                                            color:
                                              item.movieWatchListId !== -1
                                                ? "#ffffff"
                                                : "#003087",
                                          }}
                                        >
                                          <i className="ri-add-line"></i>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </>
                              </div>
                            </div>
                          </Col>
                        )
                      })}
                  </Row>
                </Container>
              </section>
            </div>
          </>
        )}
      </section>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewAll))
