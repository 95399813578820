import { takeLeading, put, all, call } from "redux-saga/effects";
import HomeActionTypes from "./home.types";
import {
  homeSyncFinish,
  tabListSyncFinish,
  newRealeseSyncFinish,
  classicSyncFinish,
  independentSyncFinish,
  originalSeriesSyncFinish,
  brandsSyncFinish,
  moviesCategorySyncFinish,
} from "./home.actions";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import { log } from "../../helpers/core.helper";

// export function* getBannerData({ payload }) {
//     try {

//         let initialSynced = false;
//         let syncinghome = false;
//         let bannerList = null;
//         let message = null;
//         const bannerData = yield UserService.getBannerData(payload);
//         if (bannerData.error != null) {
//             initialSynced = false;
//             syncinghome = false;
//             bannerList = null;
//             message = bannerData.error.response.data.message;
//         } else {
//             if (bannerData['status']) {
//                 initialSynced = true;
//                 syncinghome = true;
//                 bannerList = bannerData['data'];
//                 message = bannerData['message'];
//             } else {
//                 initialSynced = false;
//                 syncinghome = false;
//                 bannerList = null;
//                 message = bannerData['message'];
//             }
//         }

//         yield put(homeSyncFinish({ initialSynced, syncinghome, bannerList, message }));

//     } catch (err) {
//         log('home.sagas', 'getBannerData', err);
//         yield put(homeSyncFinish({ initialSynced: false, syncinghome: false, bannerList: null, message: err }));
//     }
// }

export function* getBannerData({ payload }) {
  try {
    let initialSynced = false;
    let syncinghome = false;
    let bannerList = null;
    let message = null;
    const res = yield UserService.getSerriesBannerData(payload);
    if (res.error != null) {
      initialSynced = false;
      syncinghome = false;
      bannerList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        syncinghome = true;
        bannerList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        syncinghome = false;
        bannerList = null;
        message = res["message"];
      }
    }
    yield put(
      homeSyncFinish({ initialSynced, syncinghome, bannerList, message })
    );
  } catch (err) {
    log("home.sagas", "getBannerData", err);
    yield put(
      homeSyncFinish({
        initialSynced: false,
        syncinghome: false,
        bannerList: null,
        message: err,
      })
    );
  }
}

export function* getMoviesCategoryList({ payload }) {
  try {
    let initialSynced = false;
    let moviesCategoryList = [];
    let message = null;
    const payloadData = {
      verticalId: AppConfig.API.VERTICAL_ID,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      movieCategoryId: -1,
    };

    const res = yield UserService.getMoviesCategoryList(payloadData);
    if (res.error != null) {
      initialSynced = false;
      moviesCategoryList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];

            let moviesCategoryMap = [];

            const payloadData = {
              subscriptionId: payload.subscriptionId,
              verticalId: payload.verticalId,
              employeeId: payload.employeeId,
              subTypeId: row.key,
              isPurchase: payload.isPurchase !== null ? payload.isPurchase : 0,
            };

            const responce = yield UserService.getMoviesListBySubType(
              payloadData
            );
            if (responce.error != null) {
              initialSynced = false;
              moviesCategoryList = null;
              message = responce.error.response.data.message;
            } else {
              if (responce["status"]) {
                const result = responce["data"];
                if (result != null) {
                  moviesCategoryMap = result;
                }
              }
            }
            if (moviesCategoryMap != null && moviesCategoryMap.length > 0) {
              const object = {
                moviesCategoryId: row.key,
                moviesCategoryName: row.value,
                moviesCategoryMap: moviesCategoryMap,
              };
              moviesCategoryList.push(object);
            }
          }
        }
        initialSynced = true;
        // moviesCategoryList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        moviesCategoryList = null;
        message = res["message"];
      }
    }
    yield put(
      moviesCategorySyncFinish({ initialSynced, moviesCategoryList, message })
    );
  } catch (err) {
    log("home.sagas", "getMoviesCategoryList", err);
    yield put(
      moviesCategorySyncFinish({
        initialSynced: false,
        moviesCategoryList: null,
        message: err,
      })
    );
  }
}

export function* getTabList({ payload }) {
  try {
    const payloadData = {
      employeeId: payload.employeeId,
      sphereUrl: AppConfig.API.SPHERE_TYPE_URL,
    };
    let initialSynced = false;
    let tabList = null;
    let message = null;

    const tabData = yield UserService.getTabListData(payloadData);
    if (tabData.error != null) {
      initialSynced = false;
      tabList = null;
      message = tabData.error.response.data.message;
    } else {
      if (tabData["status"]) {
        initialSynced = true;
        tabList = tabData["data"];
        message = tabData["message"];
      } else {
        initialSynced = false;
        tabList = null;
        message = tabData["message"];
      }
    }
    yield put(tabListSyncFinish({ initialSynced, tabList, message }));
  } catch (err) {
    log("home.sagas", "getTabList", err);
    yield put(
      tabListSyncFinish({ initialSynced: false, tabList: null, message: err })
    );
  }
}

export function* getOriginalSeries({ payload }) {
  try {
    let initialSynced = false;
    let originalSeriesList = null;
    let message = null;

    const res = yield UserService.getOriginalSerries(payload);
    if (res.error != null) {
      initialSynced = false;
      originalSeriesList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        originalSeriesList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        originalSeriesList = null;
        message = res["message"];
      }
    }

    yield put(
      originalSeriesSyncFinish({ initialSynced, originalSeriesList, message })
    );
  } catch (err) {
    log("home.sagas", "getOriginalSeries", err);
    yield put(
      originalSeriesSyncFinish({
        initialSynced: false,
        originalSeriesList: null,
        message: err,
      })
    );
  }
}

export function* getBrandsList({ payload }) {
  try {
    let initialSynced = false;
    let brandsList = [];
    let message = null;

    const res = yield UserService.getBrandsList(payload);
    if (res.error != null) {
      initialSynced = false;
      brandsList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.brandId,
              name: row.brandName,
              webUrl: row.brandWebsiteUrl,
            };
            brandsList.push(object);
          }
        }
        initialSynced = true;
        //  brandsList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        brandsList = null;
        message = res["message"];
      }
    }

    yield put(brandsSyncFinish({ initialSynced, brandsList, message }));
  } catch (err) {
    log("home.sagas", "getBrandsList", err);
    yield put(
      brandsSyncFinish({ initialSynced: false, brandsList: null, message: err })
    );
  }
}

export function* homeSyncStart() {
  yield takeLeading(HomeActionTypes.HOME_SYNC_START, getBannerData);
}

export function* tabListSyncStart() {
  yield takeLeading(HomeActionTypes.TAB_LIST_SYNC_START, getTabList);
}

export function* originalSeriesSyncStart() {
  yield takeLeading(
    HomeActionTypes.ORIGINAL_SERIES_SYNC_START,
    getOriginalSeries
  );
}

export function* brandsSyncStart() {
  yield takeLeading(HomeActionTypes.BRANDS_SYNC_START, getBrandsList);
}

export function* moviesCategorySyncStart() {
  yield takeLeading(
    HomeActionTypes.MOVIES_CATEGORY_SYNC_START,
    getMoviesCategoryList
  );
}

/**
 * Initialise all local sagas
 */
export function* homeSagas() {
  yield all([
    call(homeSyncStart),
    call(tabListSyncStart),
    call(originalSeriesSyncStart),
    call(brandsSyncStart),
    call(moviesCategorySyncStart),
  ]);
}
