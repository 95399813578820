import React from "react";
import "../assets/css/loader.css";
import Backdrop from "@material-ui/core/Backdrop";

class Loader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
          <img
            src={require("../assets/images/Spinner.gif")}
            style={{
              textAlign: "center",
              display: "block",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
            className="c-logo"
            alt="streamit"
          />
        </Backdrop>
      </>
    );
  }
}

export default Loader;
