import { createSelector } from "reselect";

const selectData = (state) => state.home;

export const selectHomeDataStatus = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    syncinghome: home.syncinghome,
    bannerList: home.bannerList,
    message: home.message,
  };
});

export const selectTabList = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    tabList: home.tabList,
    message: home.message,
  };
});

export const selectOriginalSeries = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    originalSeriesList: home.originalSeriesList,
    message: home.message,
  };
});

export const selectBrands = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    brandsList: home.brandsList,
    message: home.message,
  };
});

export const selectMoviesCategory = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    moviesCategoryList: home.moviesCategoryList,
    message: home.message,
  };
});

export const selectLocation = createSelector([selectData], (home) => {
  return { location: home.location };
});

export const selectEmployeeData = createSelector([selectData], (home) => {
  return home.employeeData;
});

export const selectSigninRedirectPath = createSelector([selectData], (home) => {
  return home.signinRedirectPath;
});
