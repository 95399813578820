import React from "react";
import { Form, Modal } from "react-bootstrap";
import { ReactComponent as Cardwhite } from "../../assets/images/card-white.svg";
import { ReactComponent as Category } from "../../assets/images/category.svg";
//import { ReactComponent as CardImage } from "../../assets/images/card.svg";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader";
import StripeInfo from "../../components/stripe-payment-new/stripe-payment";

class PaymentCardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      employeeId: this.props.employeeId,
      billingAddressmap: this.props.billingAddressmap,
      defaultBillingAddress: this.props.defaultBillingAddress,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeId != null) {
      this.setState({
        employeeId: this.props.employeeId,
        billingAddressmap: this.props.billingAddressmap,
        defaultBillingAddress: this.props.defaultBillingAddress,
        isLoading: 0,
      });
    } else {
      this.props.history.goBack();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const billingAddressmap = this.props.billingAddressmap;
    if (billingAddressmap !== prevProps.billingAddressmap) {
      this.setState({
        defaultBillingAddress: this.props.defaultBillingAddress,
      });
    }
  }

  render() {
    // return <div></div>
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.hideModal}
          backdrop="static"
          keyboard={false}
          centered
          id="billing-address-modal"
        >
          {this.state.isLoading === 1 ? <Loader /> : ""}

          <div id="address-modal">
            <div className="order-confirmation">
              <Modal.Header closeButton>
                <div>
                  <h4>Add New Card</h4>
                </div>
              </Modal.Header>
            </div>

            <div className="card-details" id="payment-registerpay">
              <StripeInfo
                clientSecret={this.props.paymentInfo.clientSecret}
                clientPublicKey={this.props.paymentInfo.clientPublicKey}
                orderId={this.props.paymentInfo.orderId}
                returnUrl={this.props.paymentInfo.returnUrl}
                orderTotalAmount={this.props.paymentInfo.orderTotalAmount}
                paymentIntentId={this.props.paymentInfo.paymentIntentId}
              />
              {/* 
              <Form.Group id="billing-bottom">
                <Form.Label
                  htmlFor="exampleFormControlSelect2"
                  className="payment-form-label"
                >
                  Billing Address
                </Form.Label>
                {/* <select
                  className="form-control new-payment-select"
                  id="exampleFormControlSelectPay"
                  onClick={this.props.openBillingAddress}
                >
                  {this.state.defaultBillingAddress != null ? (
                    <option value={this.state.defaultBillingAddress.addressId}>
                      {" "}
                      {this.state.defaultBillingAddress.address1},{" "}
                      {this.state.defaultBillingAddress.city},{" "}
                      {this.state.defaultBillingAddress.state}{" "}
                    </option>
                  ) : (
                    <option value="-1">Select your billing address</option>
                  )}
                </select> 
                <div
                  className="new-payment-select1"
                  onClick={this.props.openBillingAddress}
                >
                  {this.state.defaultBillingAddress != null ? (
                    <p value={this.state.defaultBillingAddress.addressId}>
                      {" "}
                      {this.state.defaultBillingAddress.address1},{" "}
                      {this.state.defaultBillingAddress.city},{" "}
                      {this.state.defaultBillingAddress.state}{" "}
                    </p>
                  ) : (
                    <p>Select your billing address</p>
                  )}
                  <Category />
                </div>
              </Form.Group>
              
           */}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default PaymentCardModal;
