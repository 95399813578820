const HomeActionTypes = {
  HOME_SYNC_START: "HOME_SYNC_START",
  HOME_SYNC_FINISH: "HOME_SYNC_FINISH",
  TAB_LIST_SYNC_START: "TAB_LIST_SYNC_START",
  TAB_LIST_SYNC_FINISH: "TAB_LIST_SYNC_FINISH",
  ORIGINAL_SERIES_SYNC_START: "ORIGINAL_SERIES_SYNC_START",
  ORIGINAL_SERIES_SYNC_FINISH: "ORIGINAL_SERIES_SYNC_FINISH",
  BRANDS_SYNC_START: "BRANDS_SYNC_START",
  BRANDS_SYNC_FINISH: "BRANDS_SYNC_FINISH",
  MOVIES_CATEGORY_SYNC_START: "MOVIES_CATEGORY_SYNC_START",
  MOVIES_CATEGORY_SYNC_FINISH: "MOVIES_CATEGORY_SYNC_FINISH",
  SET_LOCATION: "SET_LOCATION",
  SET_EMPLOYEE_DATA: "SET_EMPLOYEE_DATA",
  SET_SIGNIN_REDIRECT_PATH: "SET_SIGNIN_REDIRECT_PATH",
};

export default HomeActionTypes;
