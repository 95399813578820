import HomeActionTypes from "./home.types";

export const homeSyncStart = (payload) => ({
  type: HomeActionTypes.HOME_SYNC_START,
  payload,
});

export const homeSyncFinish = (payload) => ({
  type: HomeActionTypes.HOME_SYNC_FINISH,
  payload,
});

export const tabListSyncStart = (payload) => ({
  type: HomeActionTypes.TAB_LIST_SYNC_START,
  payload,
});

export const tabListSyncFinish = (payload) => ({
  type: HomeActionTypes.TAB_LIST_SYNC_FINISH,
  payload,
});

export const originalSeriesSyncStart = (payload) => ({
  type: HomeActionTypes.ORIGINAL_SERIES_SYNC_START,
  payload,
});

export const originalSeriesSyncFinish = (payload) => ({
  type: HomeActionTypes.ORIGINAL_SERIES_SYNC_FINISH,
  payload,
});

export const brandsSyncStart = (payload) => ({
  type: HomeActionTypes.BRANDS_SYNC_START,
  payload,
});

export const brandsSyncFinish = (payload) => ({
  type: HomeActionTypes.BRANDS_SYNC_FINISH,
  payload,
});

export const moviesCategorySyncStart = (payload) => ({
  type: HomeActionTypes.MOVIES_CATEGORY_SYNC_START,
  payload,
});

export const moviesCategorySyncFinish = (payload) => ({
  type: HomeActionTypes.MOVIES_CATEGORY_SYNC_FINISH,
  payload,
});

export const setSigninRedirectPath = (payload) => ({
  type: HomeActionTypes.SET_SIGNIN_REDIRECT_PATH,
  payload,
});

export const setLocation = (payload) => ({
  type: HomeActionTypes.SET_LOCATION,
  payload,
});

export const setEmployeeData = (payload) => ({
  type: HomeActionTypes.SET_EMPLOYEE_DATA,
  payload,
});
