import React from "react"
import { Modal, Button } from "react-bootstrap"
import { withRouter } from "react-router-dom"

class LogoutMessage extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showModal: true }
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  handleClose() {
    this.setState({ showModal: false })
    window.location.reload()
  }

  handleShow() {
    this.setState({ showModal: true })
  }

  handleLogin() {
    this.props.history.push("/authentication/sign-in")
  }

  render() {
    return (
      <>
        <Modal
          show={this.state.showModal}
          onHide={this.handleClose}
          id="session-timeout-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Session Timeout</Modal.Title>
          </Modal.Header>
          <Modal.Body>You have been logged out of the session.</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.handleClose}
              className="ok-btn"
            >
              Ok
            </Button>
            <Button
              variant="primary"
              onClick={this.handleLogin}
              className="login-btn"
            >
              Login
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default withRouter(LogoutMessage)
