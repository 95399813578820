import React from "react"
import { Container, Button, Row, Col, Form } from "react-bootstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  userSignup,
  userSignupFailure,
} from "../../store/signUp/signUp.actions"
import { selectSignUpStatus } from "../../store/signUp/signUp.selectors"
import validator from "validator"
import { NotificationManager } from "react-notifications"
import "react-notifications/lib/notifications.css"
import Backdrop from "@material-ui/core/Backdrop"
import AppConfig from "../../config/app.config"
import { ReactComponent as BLueeye } from "../../assets/images/Eye Blue.svg"
import { ReactComponent as Whiteeye } from "../../assets/images/Eye Blue 2.svg"
import { selectEmployeeData } from "../../store/home/home.selectors"
import { withRouter } from "react-router-dom"
import Loader from "../../components/Loader"

const mapStateToProps = (state) => {
  return {
    selectSignUpStatus: selectSignUpStatus(state),
    employeeData: selectEmployeeData(state),
  }
}
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      userSignup,
      userSignupFailure,
    },
    dispatch
  ),
})
const removeExtraSpace = (s) => s.replace(/\s/g, "")
var isNotifiaction = 0

export class SignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: 0,
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      password: "",
      repeatedPassword: "",
      isDisabled: "",
      passwordType: "password",
      repeatedPasswordType: "password",
      username: "",
      windowSerach: "",
      domainURL: "",
      searchparam: {},
    }
    this.handleEmail = this.handleEmail.bind(this)
    this.handlePhone = this.handlePhone.bind(this)
    this.handleFirstName = this.handleFirstName.bind(this)
    this.handleLastName = this.handleLastName.bind(this)
    this.handlePassword = this.handlePassword.bind(this)
    this.handleRepeatedPassword = this.handleRepeatedPassword.bind(this)
    this.handleUsername = this.handleUsername.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      this.props.history.push("/")
    } else {
      this.setState({
        email: "",
        phone: "",
        firstName: "",
        lastName: "",
        password: "",
        repeatedPassword: "",
        isDisabled: "",
        windowSerach: "",
        username: "",
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectSignUpStatus, userSignupFailure } = this.props
    if (isNotifiaction === 1) {
      if (selectSignUpStatus.success !== null) {
        if (selectSignUpStatus.success) {
          NotificationManager.success(
            "User Registration Successfully.",
            "",
            2000
          )
          isNotifiaction = 0

          this.timeoutHandle = setTimeout(() => {
            this.setState({ isLoading: 0, isDisabled: "" })

            this.props.history.push("/authentication/sign-in")
          }, 3000)
          userSignupFailure({
            signUpSuccess: false,
            loading: false,
            err: null,
          })
        } else {
          if (selectSignUpStatus.error !== null) {
            if (selectSignUpStatus.error !== "") {
              userSignupFailure({
                signUpSuccess: false,
                loading: false,
                err: null,
              })
              NotificationManager.error(selectSignUpStatus.error, "", 2000)
              isNotifiaction = 0
              this.timeoutHandle = setTimeout(() => {
                this.setState({
                  isLoading: 0,
                  email: "",
                  phone: "",
                  firstName: "",
                  lastName: "",
                  password: "",
                  repeatedPassword: "",
                  isDisabled: "",
                })
              }, 3000)
            }
            isNotifiaction = 0
          }
        }
      }
    }
  }

  handleEmail(event) {
    this.setState({ email: removeExtraSpace(event.target.value) })
  }
  handlePhone(event) {
    const result = /^[0-9\b]+$/
    if (event.target.value === "" || result.test(event.target.value)) {
      this.setState({ phone: removeExtraSpace(event.target.value) })
    }
  }
  handleFirstName(event) {
    this.setState({ firstName: removeExtraSpace(event.target.value) })
  }
  handleLastName(event) {
    this.setState({ lastName: removeExtraSpace(event.target.value) })
  }
  handlePassword(event) {
    this.setState({ password: event.target.value })
  }
  handleRepeatedPassword(event) {
    this.setState({ repeatedPassword: event.target.value })
  }
  handleUsername(event) {
    this.setState({ username: removeExtraSpace(event.target.value) })
  }

  signUp = () => {
    var pattern = new RegExp(/^[0-9\b]+$/)
    const rule = new RegExp(/^[a-zA-Z ]{2,20}$/)
    const { userSignup } = this.props
    if (this.state.email === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000)
    } else if (!validator.isEmail(this.state.email)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000)
    } else if (this.state.phone === "") {
      NotificationManager.warning("Please Enter Phone.")
    } else if (!pattern.test(this.state.phone)) {
      NotificationManager.warning("Please Enter Valid Phone.", "", 2000)
    } else if (this.state.phone.length !== 10) {
      NotificationManager.warning("Please Enter Valid Phone.", "", 2000)
    } else if (this.state.firstName === "") {
      NotificationManager.warning("Please Enter First Name.", "", 2000)
    } else if (!rule.test(this.state.firstName)) {
      NotificationManager.warning(
        "First Name must contains at least two letter with only characters, not allow numaric value.",
        "",
        2000
      )
    } else if (this.state.lastName === "") {
      NotificationManager.warning("Please Enter Last Name.", "", 2000)
    } else if (!rule.test(this.state.lastName)) {
      NotificationManager.warning(
        "Last Name must contains at least two letter with only characters, not allow numaric value.",
        "",
        2000
      )
    } else if (this.state.password === "") {
      NotificationManager.warning("Please Enter Password.", "", 2000)
    } else if (!validator.isStrongPassword(this.state.password)) {
      NotificationManager.warning(
        "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character and length between 8 to 20 characters.",
        "",
        2000
      )
    } else if (this.state.repeatedPassword === "") {
      NotificationManager.warning("Please Enter Repeated Password.", "", 2000)
    } else if (this.state.repeatedPassword !== this.state.password) {
      NotificationManager.warning("Password's do not match.", "", 2000)
    } else if (this.state.username === "") {
      NotificationManager.warning("Please Enter User Name.", "", 2000)
    } else {
      const payload = {
        email: this.state.email,
        phone: this.state.phone,
        name: this.state.firstName + " " + this.state.lastName,
        password: this.state.password,
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        appId: AppConfig.API.APP_ID,
        username: this.state.username,
      }

      isNotifiaction = 1
      this.setState({ isLoading: 1, isDisabled: "disabled" })
      userSignup(payload)
    }
  }

  showPassword = () => {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" })
    } else {
      this.setState({ passwordType: "password" })
    }
  }

  onLogin = () => {
    this.props.history.push("/authentication/sign-in")
  }

  showRepeatedPassword = () => {
    if (this.state.repeatedPasswordType === "password") {
      this.setState({ repeatedPasswordType: "text" })
    } else {
      this.setState({ repeatedPasswordType: "password" })
    }
  }

  isSignUpValid = () => {
    var pattern = new RegExp(/^[0-9\b]+$/)
    const rule = new RegExp(/^[a-zA-Z ]{2,20}$/)
    if (
      !validator.isEmail(this.state.email) ||
      this.state.phone === "" ||
      !pattern.test(this.state.phone) ||
      this.state.phone.length !== 10 ||
      this.state.firstName === "" ||
      !rule.test(this.state.firstName) ||
      this.state.lastName === "" ||
      !rule.test(this.state.lastName) ||
      this.state.password === "" ||
      !validator.isStrongPassword(this.state.password) ||
      this.state.repeatedPassword === "" ||
      this.state.repeatedPassword !== this.state.password ||
      this.state.username === ""
    ) {
      return false
    } else {
      return true
    }
  }

  render() {
    return (
      <section className="sign-in-page">
        {this.state.isLoading === 1 ? <Loader /> : ""}
        <Container
          className="d-container2"
          id="d-container2"
        >
          <Row className="justify-content-center align-items-center height-self-center">
            <div id="set-sign-in1">
              <Col
                className="align-self-center"
                id="set-sign-in"
              >
                <div className="sign-user_card ">
                  <div className="sign-in-page-dtaa">
                    <div className="sign-in-from w-100 m-auto">
                      <div className="sign-in-logo">
                        <Link to="/">
                          <img
                            src={require("../../assets/images/ciright-studio-blue.png")}
                            id="info-logopages"
                          />
                        </Link>
                      </div>
                      <div className="form-bg-mobile">
                        <div
                          className="form-bg-mobile1"
                          id="sign-in-logo1"
                        >
                          <Form
                            id="set-input-width"
                            onSubmit={(e) => {
                              e.preventDefault()
                            }}
                          >
                            {/* <p className='login-acc'>Create your account</p> */}
                            <Row>
                              <Col
                                xs="6"
                                className="first-name"
                              >
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    className="mb-0 sign-in-input"
                                    maxLength="20"
                                    id="txtFirstname"
                                    placeholder="First Name"
                                    value={this.state.firstName}
                                    onChange={this.handleFirstName}
                                    autoComplete="off"
                                    required
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                xs="6"
                                className="second-name"
                              >
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    className="mb-0 sign-in-input"
                                    maxLength="20"
                                    id="txtLastname"
                                    placeholder="Last Name"
                                    value={this.state.lastName}
                                    onChange={this.handleLastName}
                                    autoComplete="off"
                                    required
                                  />
                                </Form.Group>
                              </Col>
                              <Col md="12">
                                <Form.Group>
                                  <Form.Control
                                    type="email"
                                    className="mb-0 sign-in-input"
                                    id="txtEmail"
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={this.handleEmail}
                                    autoComplete="off"
                                    required
                                  />
                                </Form.Group>
                              </Col>
                              <Col md="12">
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    className="mb-0 sign-in-input"
                                    maxLength="10"
                                    id="txtPhone"
                                    placeholder="Phone Number"
                                    value={this.state.phone}
                                    onChange={this.handlePhone}
                                    autoComplete="off"
                                    required
                                  />
                                </Form.Group>
                              </Col>
                              <Col md="12">
                                <Form.Group>
                                  <Form.Control
                                    type="text"
                                    className="mb-0 sign-in-input"
                                    maxLength="20"
                                    id="txtLastname"
                                    placeholder="Username"
                                    value={this.state.username}
                                    onChange={this.handleUsername}
                                    required
                                  />
                                </Form.Group>
                              </Col>
                              <Col md="12">
                                <Form.Group>
                                  <div className="input-group">
                                    <Form.Control
                                      type={this.state.passwordType}
                                      className="mb-0 sign-in-input-pwd"
                                      id="txtPassword"
                                      maxLength="20"
                                      placeholder="Password"
                                      value={this.state.password}
                                      onChange={this.handlePassword}
                                      required
                                    />
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text sign-pwd-i-icon"
                                        id="inputGroupPrepend3"
                                        onClick={this.showPassword}
                                        style={{
                                          backgroundColor: "#141414",
                                          border: "1px solid #404043",
                                          color: "#fff",
                                        }}
                                      >
                                        {/* <i className={this.state.passwordType === 'password' ? 'd-eye-off' : 'd-eye-on'}></i> */}
                                        {this.state.passwordType !==
                                        "password" ? (
                                          <BLueeye />
                                        ) : (
                                          <Whiteeye />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col md="12">
                                <Form.Group id="sign-group">
                                  <div className="input-group">
                                    <Form.Control
                                      type={this.state.repeatedPasswordType}
                                      className="mb-0 sign-in-input-pwd"
                                      maxLength="20"
                                      id="txtRepeatedPassword"
                                      placeholder="Repeat Password"
                                      value={this.state.repeatedPassword}
                                      onChange={this.handleRepeatedPassword}
                                      required
                                    />
                                    <div className="input-group-prepend">
                                      <span
                                        className="input-group-text sign-pwd-i-icon"
                                        id="inputGroupPrepend3"
                                        onClick={this.showRepeatedPassword}
                                        style={{
                                          backgroundColor: "#141414",
                                          border: "1px solid #404043",
                                          color: "#fff",
                                        }}
                                      >
                                        {/* <i className={this.state.repeatedPasswordType === 'password' ? 'd-eye-off' : 'd-eye-on'}></i> */}
                                        {this.state.repeatedPasswordType !==
                                        "password" ? (
                                          <BLueeye />
                                        ) : (
                                          <Whiteeye />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                            <div className="set-sign-up">
                              <div
                                className="d-flex justify-content-center links login-acc"
                                id="sign-up"
                              >
                                Already have an account?
                                <Link
                                  to="#"
                                  className="text-primary"
                                  onClick={this.onLogin}
                                >
                                  <span className="set-app-signin">
                                    Sign in
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <Button
                              type="submit"
                              onClick={this.signUp}
                              className={`btn my-2 ${
                                this.isSignUpValid() ? "" : "disabled"
                              }`}
                              id="sign-inbtn"
                            >
                              Sign Up
                            </Button>
                            <div className="sign-up-policy">
                              <p>
                                By signing up you agree to our{" "}
                                {/* <Link target="_blank" to="/terms-of-use"> */}
                                <Link to="#">
                                  <span className="terms-use">
                                    Terms of Service{" "}
                                  </span>
                                </Link>
                                and{" "}
                                {/* <Link target="_blank" to="/privacy-policy"> */}
                                <Link to="#">
                                  <span className="terms-use">
                                    {" "}
                                    Privacy Policy
                                  </span>
                                </Link>
                              </p>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp))
