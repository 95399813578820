import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
// import { NotificationManager } from 'react-notifications';
// import 'react-notifications/lib/notifications.css';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      faq: null,
      faqMap: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: 1 });
    this.getFaqList();
  }

  setfaq = (value) => {
    this.setState({
      faq: this.state.faq === parseInt(value) ? null : parseInt(value),
    });
  };

  getFaqList = () => {
    const payload = {
      sphereUrl: AppConfig.API.SPHERE_TYPE_URL,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
    };
    UserService.getFaqList(payload)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0 });
          //this.props.history.goBack();
          // NotificationManager.warning(res.error.response.data.message, '', 2000);
        } else {
          if (res["status"]) {
            var resultList = res["data"];
            const faqs = resultList.filter(
              (faqList) => faqList.subTypeId === 245280
            );
            if (faqs && faqs[0]) {
              this.setState({
                faqMap: faqs[0].faqsList,
                faq: faqs[0].faqsList[0].id,
                isLoading: 0,
              });
            } else {
              this.setState({ isLoading: 0 });
            }
          } else {
            this.setState({ isLoading: 0 });
            // NotificationManager.error(res['message'], '', 2000);
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0 });
        // NotificationManager.error(err, '', 2000);
      });
  };

  render() {
    return (
      <div>
        {this.state.isLoading === 1 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: window.innerHeight,
              width: "100%",
            }}
          >
            <img
              src={require("../../assets/images/Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </div>
        ) : (
          <section
            className="iq-main-slider p-0 iq-rtl-direction"
            style={{ position: "unset" }}
          >
            {/* <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{ backgroundImage: `url(${faqImage})`, height: '50vh' }}>
                            <Container fluid>
                                <Row className="align-items-center">
                                    <Col sm="12">
                                        <nav aria-label="breadcrumb" className="text-center iq-breadcrumb-two">
                                            <h2 className="title">FAQ</h2>
                                        </nav>
                                    </Col>
                                </Row>
                            </Container>
                        </div> */}
            <main id="main" className="site-main">
              <Container>
                <Row>
                  <Col lg="12" sm="12" className="faq-col">
                    <div className="iq-privacy-policy">
                      <div className="privacy-text-sec">
                        <p>FAQ</p>
                      </div>
                      <div className="iq-privacy-policy2">
                        <div className="iq-accordion iq-accordion-square">
                          {this.state.faqMap.length > 0 ? (
                            <>
                              {this.state.faqMap.map((item, key) => {
                                return (
                                  <div
                                    key={key}
                                    className={`iq-accordion-block  ${
                                      item.id
                                    } ${
                                      this.state.faq &&
                                      parseInt(this.state.faq) === item.id
                                        ? "iq-active"
                                        : ""
                                    }`}
                                    onClick={() => this.setfaq(item.id)}
                                  >
                                    <div className="iq-accordion-title">
                                      <div
                                        className="iq-icon-right"
                                        style={{ borderRadius: "50%" }}
                                      >
                                        <i
                                          aria-hidden="true"
                                          className="fa fa-minus active"
                                        ></i>
                                        <i
                                          aria-hidden="true"
                                          className="fa fa-plus inactive"
                                        ></i>
                                      </div>
                                      <h5 className="mb-0 accordion-title iq-heading-title">
                                        {item.question}
                                      </h5>
                                    </div>
                                    <div
                                      className={`iq-accordion-details ${
                                        parseInt(this.state.faq) === item.id
                                          ? "d-block"
                                          : "d-none"
                                      }`}
                                    >
                                      <p
                                        className="iq-content-text"
                                        style={{ fontFamily: "inherit" }}
                                      >
                                        {" "}
                                        {item.answer}{" "}
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            " NO DATA"
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </main>
          </section>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
