import HomeActionTypes from "./home.types";
// Set the reducers initial state.
const INITIAL_STATE = {
  initialSynced: false,
  syncinghome: false,
  bannerList: null,
  message: null,
  tabList: null,
  originalSeriesList: null,
  brandsList: null,
  moviesCategoryList: null,
  location: null,
  employeeData: null,
  signinRedirectPath: null,
};

// Define scenarios for reducers.
const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HomeActionTypes.HOME_SYNC_START:
      return {
        ...state,
        syncinghome: true,
      };
    case HomeActionTypes.HOME_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        syncinghome: action.payload.syncinghome,
        bannerList: action.payload.bannerList,
        message: action.payload.message,
      };
    case HomeActionTypes.TAB_LIST_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.TAB_LIST_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        tabList: action.payload.tabList,
        message: action.payload.message,
      };

    case HomeActionTypes.ORIGINAL_SERIES_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.ORIGINAL_SERIES_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        originalSeriesList: action.payload.originalSeriesList,
        message: action.payload.message,
      };

    case HomeActionTypes.BRANDS_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.BRANDS_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        brandsList: action.payload.brandsList,
        message: action.payload.message,
      };

    case HomeActionTypes.MOVIES_CATEGORY_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.MOVIES_CATEGORY_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        moviesCategoryList: action.payload.moviesCategoryList,
        message: action.payload.message,
      };
    case HomeActionTypes.SET_LOCATION:
      return {
        ...state,
        location: action.payload.location,
      };
    case HomeActionTypes.SET_EMPLOYEE_DATA:
      return {
        ...state,
        employeeData: action.payload,
      };
    case HomeActionTypes.SET_SIGNIN_REDIRECT_PATH:
      return {
        ...state,
        signinRedirectPath: action.payload.signinRedirectPath,
      };

    default:
      return state;
  }
};

export default homeReducer;
