import React from "react";
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../Card";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppConfig from "../../config/app.config";
import { ethers } from "ethers";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import CustomToggle from "../dropdowns";
import Backdrop from "@material-ui/core/Backdrop";
//img

import { ReactComponent as Iconwhite } from "../../assets/images/header-right.svg";
import { ReactComponent as IconWhite1 } from "../../assets/images/header-right2.svg";
import { ReactComponent as Polygon } from "../../assets/images/black-polygon.svg";
import { ReactComponent as Homeicon } from "../../assets/images/home.svg";
import { ReactComponent as Footericon } from "../../assets/images/bottom-line.svg";
import { ReactComponent as Homefill } from "../../assets/images/home-white.svg";
import { Drawer } from "@material-ui/core";
import { selectLocation } from "../../store/home/home.selectors";
import { selectEmployeeData } from "../../store/home/home.selectors";
import { withRouter } from "react-router-dom";
import Loader from "../Loader";

const mapStateToProps = (state) => {
  return {
    selectLocation: selectLocation(state),
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class HeaderManu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeId: null,
      employeeName: null,
      employee: null,
      isSession: 0,
      profileImage: "",
      routeName: "",
      Balance: "",
      address: "",
      isProfileDropdownVisible: false,
      isDrawerVisible: false,
      currentPath: null,
      isLoading: 0,
    };
  }

  getInterval = null;
  getMetamaskInterval = null;

  componentDidMount() {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      const pathArray = window.location.pathname.split("/");
      this.setState({
        employeeId: this.props.employeeData?.employeeId,
        employeeName: this.props.employeeData?.name,
        employee: this.props.employeeData,
        isSession: 1,
        routeName: pathArray[1],
        profileImage:
          AppConfig.API.USER_PROFILE +
          this.props.employeeData?.employeeId +
          "&random=" +
          Date.now(),
      });
      this.getMetamaskInterval = setInterval(
        () => this.getMetamskAccountInfo(),
        100
      );
    }

    this.getInterval = setInterval(() => this.getLoginDetail(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.getMetamaskInterval);
    clearInterval(this.getInterval);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectLocation.location != null &&
      this.props.selectLocation.location.pathname !== this.state.currentPath
    ) {
      this.setState({
        currentPath: this.props.selectLocation.location.pathname,
      });
    }

    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      const pathArray = window.location.pathname.split("/");
      if (this.state.routeName !== pathArray[1]) {
        this.setState({
          routeName: pathArray[1],
        });
      }
      if (this.state.isSession === 0) {
        this.setState({
          isSession: 1,
        });
      }
    } else {
      if (this.state.routeName !== "") {
        this.setState({
          routeName: "",
        });
      }
      if (this.state.isSession === 1) {
        this.setState({
          isSession: 0,
        });
      }
    }

    // this.checkForCookie();
  }

  getLoginDetail = async () => {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null &&
      this.props.employeeData?.employeeId !== this.state.employeeId
    ) {
      const pathArray = window.location.pathname.split("/");
      this.setState({
        employeeId: this.props.employeeData?.employeeId,
        employeeName: this.props.employeeData?.name,
        employee: this.props.employeeData,
        isSession: 1,
        routeName: pathArray[1],
        profileImage:
          AppConfig.API.USER_PROFILE +
          this.props.employeeData?.employeeId +
          "&random=" +
          Date.now(),
      });
      this.getMetamaskInterval = setInterval(
        () => this.getMetamskAccountInfo(),
        100
      );
    }
  };

  logout = async () => {
    this.setState({ isLoading: 1 });
    localStorage.clear();
    NotificationManager.success("Signed Out Successfully.", "", 2000);
    this.timeoutHandle = setTimeout(() => {
      this.setState({ employeeId: "", isSession: 0, isLoading: 0 });
      window.location.reload();
      this.props.history.replace("/");
    }, 1000);
  };

  connectMetaMask = () => {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      if (window.ethereum) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((res) => this.accountChangeHandler(res[0]));
      } else {
        window.open("https://metamask.io/download", "_blank");
      }
    } else {
      NotificationManager.warning("Please login your account.", "", 2000);
    }
  };

  accountChangeHandler = (account) => {
    this.setState({
      address: account,
    });
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [account, "latest"],
      })
      .then((balance) => {
        this.setState({
          Balance: ethers.utils.formatEther(balance),
        });
        //this.byWithWallet();
      });
  };

  async getMetamskAccountInfo() {
    if (window.ethereum) {
      let val = window.ethereum.isConnected();
      if (val) {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length !== 0) {
          const account = accounts[0];
          if (account !== this.state.address) {
            this.setState({
              address: account,
            });
          }
        } else {
          if (this.state.address !== "") {
            this.setState({
              address: "",
            });
          }
        }
      }
    } else {
      if (this.state.address !== "") {
        this.setState({
          address: "",
        });
      }
    }
  }
  shortenContractUrl = (url) =>
    url.substring(0, 6) + "..." + url.substring(url.length - 4, url.length);

  render() {
    return (
      <header id="main-header">
        {this.state.isLoading === 1 ? <Loader /> : ""}
        <div className="main-header">
          <Container fluid id="header-container">
            <Row id="d-header">
              <Col sm="12" id="d-header-col">
                <div
                  className={`header-profile ${
                    this.state.employeeId === null ? "small" : ""
                  }`}
                >
                  {this.state.employeeId !== null && (
                    <>
                      <img
                        src={this.state.profileImage}
                        className="img-fluid avatar-40 rounded-circle"
                        alt="user"
                        onClick={() => {
                          this.setState({
                            isDrawerVisible: !this.state.isDrawerVisible,
                          });
                        }}
                      />
                      <p
                        className={`${
                          this.state.isDrawerVisible === true
                            ? "profilename-text"
                            : ""
                        }`}
                      >
                        {this.state.employeeName}
                      </p>
                    </>
                  )}
                </div>

                <div
                  className={`center-logo ${
                    this.state.employeeId === null ? "left-logo" : ""
                  }`}
                >
                  <Link to="/">
                    {" "}
                    <img
                      src={require("../../assets/images/ciright-studio.png")}
                      className="c-logo"
                    />
                  </Link>
                </div>

                <div className="navbar-right menu-right" id="right-sidetoggle">
                  <Dropdown
                    className="nav-item m-0"
                    show={this.state.isProfileDropdownVisible}
                    onToggle={(isOpen, event, metadata) => {
                      if (metadata && metadata.source === "rootClose") {
                        this.setState({ isProfileDropdownVisible: false });
                      }
                    }}
                  >
                    <Dropdown.Toggle
                      href="#"
                      as={CustomToggle}
                      onClick={() => {
                        this.setState({
                          isProfileDropdownVisible:
                            !this.state.isProfileDropdownVisible,
                        });
                      }}
                      variant="search-toggle position-relative"
                    >
                      {this.state.isProfileDropdownVisible ? (
                        <Iconwhite />
                      ) : (
                        <Iconwhite />
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="iq-sub-dropdown dropdown-menu dropdown-menu-right">
                      <Card className="iq-card shadow-none m-0">
                        <Card.Body className="iq-card-body" id="polygon-set">
                          <Polygon className="black-polygon" />
                          <div className="toggle-contents">
                            {this.state.employeeId !== null ? (
                              <Button
                                type="button"
                                onClick={this.logout}
                                variant="next action-button app-signin"
                                style={{ color: "#fff" }}
                              >
                                {"Logout"}
                              </Button>
                            ) : (
                              <>
                                <Link to="/authentication/sign-up">
                                  <Button
                                    type="button"
                                    variant="next action-button app-create"
                                    style={{ color: "#fff" }}
                                  >
                                    Sign up
                                  </Button>
                                </Link>
                                <div className="toggle-text">
                                  <hr className="toggle-text-line"></hr>
                                  <p>OR</p>
                                  <hr className="toggle-text-line"></hr>
                                </div>
                                <Link to="/authentication/sign-in">
                                  <Button
                                    type="button"
                                    variant="next action-button app-signin"
                                    style={{ color: "#fff" }}
                                  >
                                    {"Login"}
                                  </Button>
                                </Link>
                              </>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <React.Fragment>
          {this.state.employee != null && (
            <Drawer
              id="header-drawer"
              anchor={"left"}
              open={this.state.isDrawerVisible}
              onClose={() => {
                this.setState({ isDrawerVisible: false });
              }}
            >
              <div className="websites-urlset">
                <div className="header-profile-drawer">
                  <img
                    src={this.state.profileImage}
                    className="img-fluid rounded-circle drawer-profile"
                    alt="user"
                    onClick={() => {
                      this.setState({
                        isDrawerVisible: !this.state.isDrawerVisible,
                      });
                    }}
                  />
                  <p>{this.state.employeeName} </p>
                </div>
              </div>
              <div className="drawer-content">
                <Link
                  to="/"
                  onClick={() => this.setState({ isDrawerVisible: false })}
                  className="header-category"
                >
                  <div className="right-icon">
                    {this.state.currentPath === "/" ? (
                      <Homefill />
                    ) : (
                      <Homeicon />
                    )}
                    {/* <i className={`ri-home-4-fill ></i> */}
                  </div>
                  <div
                    className={`media-body ${
                      this.state.currentPath === "/"
                        ? "set-profile-info-active"
                        : "set-profile-info"
                    }`}
                  >
                    <h6 className="my-0 "> Home </h6>
                  </div>
                </Link>
                <Link
                  to="/user-profile"
                  onClick={() => this.setState({ isDrawerVisible: false })}
                  className="header-category"
                >
                  <div className="right-icon">
                    <i
                      className={`ri-file-user-line ${
                        this.state.currentPath === "/user-profile"
                          ? "drawer-icon-active"
                          : "drawer-icon"
                      }`}
                    ></i>
                  </div>
                  <div
                    className={`media-body ${
                      this.state.currentPath === "/user-profile"
                        ? "set-profile-info-active"
                        : "set-profile-info"
                    }`}
                  >
                    <h6 className="my-0 "> My Profile </h6>
                  </div>
                </Link>
                <Link
                  to="/change-password"
                  onClick={() => this.setState({ isDrawerVisible: false })}
                  className="header-category"
                >
                  <div className="right-icon">
                    <i
                      className={`ri-lock-password-line  ${
                        this.state.currentPath === "/change-password"
                          ? "drawer-icon-active"
                          : "drawer-icon"
                      }`}
                    ></i>
                  </div>
                  <div
                    className={`media-body ${
                      this.state.currentPath === "/change-password"
                        ? "set-profile-info-active"
                        : "set-profile-info"
                    }`}
                  >
                    <h6 className="my-0 "> Change Password </h6>
                  </div>
                </Link>
                <Link
                  to="/watchlist"
                  onClick={() => this.setState({ isDrawerVisible: false })}
                  className="header-category"
                >
                  <div className="right-icon">
                    <i
                      className={`ri-file-list-fill  ${
                        this.state.currentPath === "/watchlist"
                          ? "drawer-icon-active"
                          : "drawer-icon"
                      }`}
                    ></i>
                  </div>
                  <div
                    className={`media-body ${
                      this.state.currentPath === "/watchlist"
                        ? "set-profile-info-active"
                        : "set-profile-info"
                    }`}
                  >
                    <h6 className="my-0 "> My Watchlist </h6>
                  </div>
                </Link>

                <Link
                  to="/purchases"
                 // to="#"
                  onClick={() => this.setState({ isDrawerVisible: false })}
                  className="header-category"
                >
                  <div className="right-icon">
                    <i
                      className={`ri-bank-card-line ${
                        this.state.currentPath === "/purchases"
                          ? "drawer-icon-active"
                          : "drawer-icon"
                      }`}
                    ></i>
                  </div>
                  <div
                    className={`media-body ${
                      this.state.currentPath === "/purchases"
                        ? "set-profile-info-active"
                        : "set-profile-info"
                    }`}
                  >
                    <h6 className="my-0 "> Purchases </h6>
                  </div>
                </Link>
              </div>
            </Drawer>
          )}
          <Drawer
            id="header-drawer"
            className="bottomDrawer"
            anchor={"bottom"}
            open={this.state.isProfileDropdownVisible}
            onClose={() => {
              this.setState({ isProfileDropdownVisible: false });
            }}
          >
            <Card className="iq-card shadow-none m-0">
              <Card.Body className="iq-card-body" id="polygon-set">
                <div className="footer-icon">
                  <Footericon />
                </div>
                <div id="right-sidetoggle">
                  <div className="toggle-contents" id="toggle-contents">
                    {this.state.employeeId !== null ? (
                      <Button
                        type="button"
                        onClick={this.logout}
                        variant="next action-button app-signin"
                        style={{ color: "#fff" }}
                      >
                        {"Logout"}
                      </Button>
                    ) : (
                      <>
                        <Link to="/authentication/sign-up">
                          <Button
                            type="button"
                            variant="next action-button app-create"
                            style={{ color: "#fff" }}
                          >
                            Sign up
                          </Button>
                        </Link>
                        <div className="toggle-text">
                          <hr className="toggle-text-line"></hr>
                          <p>OR</p>
                          <hr className="toggle-text-line"></hr>
                        </div>
                        <Link to="/authentication/sign-in">
                          <Button
                            type="button"
                            variant="next action-button app-signin"
                            style={{ color: "#fff" }}
                          >
                            {"Login"}
                          </Button>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Drawer>
        </React.Fragment>
      </header>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderManu)
);
