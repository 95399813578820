import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import UserProfile from "../views/profile/user-profile";
import ChangePassword from "../views/profile/change-password";
import Home from "../views/home/home";
import MovieDetails from "../views/movie-details/movie-details";
import SeriesDetails from "../views/series-details/series-details";
import Faq from "../views/footer-page/faq";
import PrivacyPolicy from "../views/footer-page/privacy-policy";
import TermsOfUse from "../views/footer-page/terms-of-use";
import ViewAll from "../views/view-all/view-all-movie";
import Watchlist from "../views/watchlist/watchlist";
import Error from "../views/Error/Error";
import Purchases from "../views/purchases/purchases";
import PurchasesDetails from "../views/purchases-details/purchases-details";
import MissionPurpose from "../views/footer-page/mission-purpose";

const MainRoute = () => {
  return (
    <TransitionGroup>
      <CSSTransition
        // key={location.key}
        classNames="fade"
        timeout={300}
      >
        <Switch>
          <Route path="/user-profile" component={UserProfile} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/watchlist" component={Watchlist} />
          <Route path="/movie-details/:movieId" component={MovieDetails} />
          <Route
            path="/series-details/:seriesId/:seasonId/:episodeId"
            component={SeriesDetails}
          />
          <Route path="/series-details/:seriesId" component={SeriesDetails} />
          <Route path="/faq" component={Faq} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/view-all/:movieType" component={ViewAll} />
          <Route path="/purchases" component={Purchases} />
          <Route
            path="/purchases-details/:seriesId"
            component={PurchasesDetails}
          />
          <Route path="/mission-purpose" component={MissionPurpose} />

          {/* homepage */}
          <Route path="/" exact component={Home} />
          <Route path="*" component={Error} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default MainRoute;
